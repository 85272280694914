export class TrainerCopmanyDetials{
    constructor() {
            this.trainerId=null;
       this.companyPanNumber='';
	this.companyPanFileUrl='';
	this.msmeRegistrationNumber='';
	this.msmeCertificateUrl='';
    this.registeredEntity='';
	this.registeredEntityAddress='';
    }
}