export class EducationDto {
  constructor() {
    this.id=null;
    this.trainerId = null;
    this.college = "";
    this.degree = "";
    this.description = "";
    this.startDate = null;
    this.endDate = null;
    this.specialization = "";
  }
}
