/* globals Store ,Router */

import tapApi from "@/utils/api/tap-api";
import { TrainerProfile } from "@/models";
import TrainerJourney from "../../models/TrainerJourney";
import { UpdateTrainerProfile } from "../../models/UpdateTrainerProfile";
import { References } from "../../models/References";
import { Testimonial } from "../../models/Testimonial";
import { Certification } from "../../models/Certification";
import { Awards } from "../../models/Awards";
import { Patents } from "../../models/Patent";
import { Publications } from "../../models/Publicaitons";
import { TopCourses } from "../../models/TopCourses";
import { Legal } from "../../models/Legal";
import Constants from "../../utils/Constants";
import { TrainerFiles } from "../../models/TrainerFiles";
import { PaytmentDetialsDto } from "../../models/PaymentDetailsDto";
import { TrainerOriginalFiles } from "../../models/TrainerOriginalFiles";
import { WorkExperince } from "../../models/WorkExperince";
import { TrainerGuestFaculty } from "../../models/TrainerGuestFaculty";
import { AdminCommnets } from "../../models/AdminCommets";
import { TrainerSkills } from "../../models/TrainerSkills";
import { TrainerTargetAudience } from "../../models/TrainerTargetAudience";
import { TrainerToolsAndPlatfrom } from "../../models/TrainerToolsAndPlatform";
import { TrainerSkillServicesDelivery } from "../../models/TrainerSkillServicesDelivery";
import { SkillServicesDto } from "../../models/SkillServicesDto";
import { EducationDto } from "../../models/Education";
import { LinkedinScrapperDto } from '../../models/LinkedinScrapperDto'
import axios from "axios"
const state = {
  profile: new UpdateTrainerProfile(),
  createProfile: new TrainerProfile(),
  loading: false,
  trainerList: [],
  profilePictureSrc: "",
  editMode: {
    trainerProfile: false,
    adminConsole: false,
    originalFiles: false,
    paymentDetails: false,
    legal: false
  },
  TrainerJourney: new TrainerJourney(),
  referencesList: [],
  reference: new References(),
  testimonial: new Testimonial(),
  testimonialList: [],
  certification: new Certification(),
  award: new Awards(),
  patent: new Patents(),
  publication: new Publications(),
  extraMilesList: [],
  certificationList: [],
  publicationList: [],
  awardsList: [],
  patentList: [],
  topCourse: new TopCourses(),
  coursesList: [],
  legal: new Legal(),
  adminComments: new AdminCommnets(),
  adminCommentsList: [],
  trainerFiles: new TrainerFiles(),
  legalDocumentSource: "",
  paymentDetials: new PaytmentDetialsDto(),
  trainerOriginalFiles: new TrainerOriginalFiles(),
  workEx: new WorkExperince(),
  workExpList: [],
  guestFacultyExp: new TrainerGuestFaculty(),
  guestFauculyList: [],
  education: new EducationDto(),
  educationList: [],
  // skill and services
  trainerSkills: new TrainerSkills(),
  trainerTargetAudience: new TrainerTargetAudience(),
  trainerToolsAndPlatfrom: new TrainerToolsAndPlatfrom(),
  trainerSkillSerivices: new TrainerSkillServicesDelivery(),
  trainerSkillServicesDto: new SkillServicesDto(),
  deleteAlertBoxSatus: false,
  afterOk: "",
  linkedInScrapperDto: new LinkedinScrapperDto(),
  bulkUploadList: [],
  snackbar_linkedin: false,
  snackBarTrainerProfile:false,
  alertBoxScapper: {
    headerText: "",
    status: false,
    message: "",
    afterDeleteConfirmation: "",
  }
};
const getters = {};

const actions = {
  logout({ commit }) {
    commit("logout");
  },

  closeScrapperAlertBox({ commit }) {
    commit("setAlertBoxScapper",null)
  },
  async updateTheReamaiingDataFromScrapper({ commit },modifiedScrappedData) {
    commit("setLoading",true);
    let updateTraineredTrainer = await tapApi.postData('tap/update-from-scrapper', modifiedScrappedData)
    if (updateTraineredTrainer.data.status == "SUCCESS") {
      Router.push("/");
      commit("setLinkedInScrapperDto",null)
    }
    commit("setLoading",false);
 },
  async getRemainingData({ commit }, trainerId) {
    commit("setLoading",true);
    let getRestScrappedData = await tapApi.getData(`tap/get-remaining-data?trainerId=${trainerId}`)
    console.log('get reposne ',getRestScrappedData );
    if (getRestScrappedData.data.status == "SUCCESS") {
      // commit to scrapper // call the update api 
      commit("setLinkedInScrapperDto", getRestScrappedData.data.data[0])
      Router.push("/linkedin-scraper");
    }
    if (getRestScrappedData.data.status == "NOTFOUND") {
      console.log('code is here not found');
      commit("setTrainerProfileSnackBar",true);
    }
    
    commit("setLoading",false);
    
  },
  async bulkUpload({ commit }, file) {
    console.log('file' , file);
    let formData = new FormData()
    formData.append("trainerDb",file)
    commit("setLoading",false);
    console.log('file', file);
    await axios.post('http://localhost:3001/bulkupload',formData).then(async(res)=>{
   console.log('response  ', res.data.data);
      let bulkUploadResponse = await tapApi.postData(`tap/bulk-upload`, res.data.data)
      console.log(bulkUploadResponse);
 })
  },
  async createTrainerUsingLinkedinScrapper({ commit }, modifiedScrappedData) {
    commit("setLoading", true);
    let creatTrainerResponse = await tapApi.postData('tap/createTrainerUsingScrapper', modifiedScrappedData)
    if (creatTrainerResponse.data.status == "SUCCESS") {
      console.log('sucessfully created ', creatTrainerResponse);
      // commit("setProfile", creatTrainerResponse.data.data[0]);
      Router.push("/home");
      commit("setLinkedInScrapperDto",null)
    } else {
      Store.dispatch("project/manageAlertBox", {
        status: true,
        text: creatTrainerResponse.data.statusMessage,
        headerText: "Failed",
        afterOk: () => {}
      });
    }
    commit("setLoading", false);
    
  },
  async updateTheScrappedDate({commit, state },{type, index ,confirmDelete}) {
    let scrappedData = Object.assign({}, state.linkedInScrapperDto)
    // first show alert box then on confirmation delete it 
    if (!confirmDelete) {
      commit("setAlertBoxScapper",{
        headerText: 'Alert',
        status: true,
        message: 'Are u sure you want to delete this ?',
        afterDeleteConfirmation: {
          type: type,
          index:index
        },
      }) 
    }
    if (confirmDelete) {
      if (type == "Work") {
        scrappedData.workExperince.splice(index, 1);
      } else if (type == "Education") {
        scrappedData.education.splice(index, 1);
      } else if (type == "Certification") {
        scrappedData.certifications.splice(index, 1);
      } else if (type == "Patent") {
        scrappedData.patents.splice(index, 1);
      } else if (type =="Publication") {
        scrappedData.publication.splice(index, 1);
      } else if (type=="Award") {
        scrappedData.awards.splice(index, 1);
      }
      //setAlertBoxScapper
      commit("setAlertBoxScapper", null)
      
      commit("setLinkedInScrapperDto", scrappedData)
    }
    
    
  },
  async getScrappedData({ commit ,state }, { trainerProfile,linkedinProfileUrl,cb }) {
    // commit("setLoading", true);
    // show the loading spinner with message
    // scrapping data takes time please wait for a while
    commit("setSnackbar", true)
    let profile  =Object.assign({},state.createProfile)
    let scrapperResponse = await tapApi.getData(`tap/get-scrapped-data?linkedInUrl=${trainerProfile.linkedinProfileUrl}`)
    if (scrapperResponse.data.status == "SUCCESS") {
      if (profile.firstName) {
        scrapperResponse.data.data[0].trainerProfile.firstName =profile.firstName
      }if (profile.lastName) {
        scrapperResponse.data.data[0].trainerProfile.lastName =profile.lastName
      }if (profile.primaryEmailId) {
        scrapperResponse.data.data[0].trainerProfile.primaryEmailId =profile.primaryEmailId
      }
      scrapperResponse.data.data[0].trainerProfile.linkedinProfileUrl = linkedinProfileUrl
      console.log('reposne ' ,scrapperResponse.data.data[0]);
      commit("setLinkedInScrapperDto", scrapperResponse.data.data[0])
      if (cb) {
        cb()
      }
      Router.push("/linkedin-scraper");
    } else {
      // show the alert box on ok proceed to 
      commit("setLinkedInScrapperDto", null)
      Store.dispatch("project/manageAlertBox", {
        status: true,
        text: scrapperResponse.data.statusMessage,
        headerText: "Failed to fetch from scrapper",
        afterOk: () => {}
      });
      commit("setSnackbar",null)
    }
    commit("setSnackbar",null)
    //  commit("setLoading",false);
  },
  async createProfileUsingScrappedData({ commit }) {
    commit("setLoading", true);
  },
  async getSkillNames({ commit }, trainerId) {
    commit("setLoading", true);
    let skillsOfTrainer = await tapApi.getData(`tap/get-skill-considered?trainerId=${trainerId}`);
    console.log('skill name', skillsOfTrainer);
    commit("setLoading",false);
    return skillsOfTrainer.data.data;
   
  },
  closeDeleteDailog({ commit }) {
    console.log("close dailog calledd");
    commit("setDelelteAlertDailog", false);
  },
  async getAllAdminComments({ commit, state }, { type, trainerId }) {
    commit("setLoading", false);
    console.log("state  ---", state);
    let adminComments = await tapApi.getData(
      `tap/getAllAdminComments?trainerId=${trainerId}&&type=${type}`
    );
    console.log("admincomments list ", adminComments);
    if (adminComments.data.status == "SUCCESS") {
      commit("setAdminCommentsList", adminComments.data.data);
    }
  },


  async deleteToolsAndPlatfrom(
    { commit, state },
    { toolsAndPlatfrom, delteConfirmation, type }
  ) {
    let skillServiceDto = Object.assign({}, state.trainerSkillServicesDto);
    if (delteConfirmation) {
      commit("setLoading", true);
      // go and delete the file and show a sanck bar delete sucessfully
      let deleteToolsResposne = await tapApi.deleteData(
        "tap/delete-tools-platform/" + toolsAndPlatfrom.id
      );
      if (deleteToolsResposne.data.status == "SUCCESS") {
        // base on type filter dto
        if (type == "TOOLSANDPLATFORM") {
          skillServiceDto.toolsAndPlatFrom = skillServiceDto.toolsAndPlatFrom.filter(
            (department) => department.id != toolsAndPlatfrom.id
          );
        } else if (type == "TARGETINDUSTRY") {
          skillServiceDto.targetIndustryAndDepartment = skillServiceDto.targetIndustryAndDepartment.filter(
            (department) => department.id != toolsAndPlatfrom.id
          );
        } else if (type == "TARGETDEPARTMENT") {
          skillServiceDto.targetDepartment = skillServiceDto.targetDepartment.filter(
            (department) => department.id != toolsAndPlatfrom.id
          );
        }
        commit("setTrainerSkillServicesDto", skillServiceDto);
        commit("setDelelteAlertDailog", false);
        commit("setAfterOk", null);
      }
      commit("setLoading", false);
    } else {
      commit("setDelelteAlertDailog", true);
      commit("setAfterOk", {
        toolsAndPlatfrom: toolsAndPlatfrom,
        type: type
      });
      // show dailog
    }
    console.log(state);
  },
  async createAdminComments(
    { commit, state, rootState },
    { adminComment, cb }
  ) {
    commit("setLoading", true);
    let user = rootState.userAuthenticaiton.user;
    let adminCommentList = Object.assign([], state.adminCommentsList);
    console.log(adminCommentList);
    adminComment.userName = user.firstName + " " + user.lastName;
    let createAdminCommentResponse = await tapApi.postData(
      "tap/createAdminComment",
      adminComment
    );
    if (createAdminCommentResponse.data.status == "SUCCESS") {
      adminCommentList.unshift(createAdminCommentResponse.data.data[0]);
      commit("setAdminCommentsList", adminCommentList);
      commit("setAdminComments", null);
      if (cb) cb();
    }
    commit("setLoading", false);
    if (cb) cb();
  },
  async updateEducation({ commit, state }, { education, cb }) {
    commit("setLoading", true);
    let educationList = Object.assign([], state.educationList);
    let updatedEducation = await tapApi.putData(
      "tap/update-education",
      education
    );
    if (updatedEducation.data.status == "SUCCESS") {
      educationList.forEach((education) => {
        if (education.id == updatedEducation.data.data[0].id) {
          education.college = updatedEducation.data.data[0].college;
          education.degree = updatedEducation.data.data[0].degree;
          education.description = updatedEducation.data.data[0].description;
          education.startDate = updatedEducation.data.data[0].startDate;
          education.endDate = updatedEducation.data.data[0].endDate;
          education.specialization =
            updatedEducation.data.data[0].specialization;
        }
      });

      commit("setEducationList", educationList);
      commit("setEducation", null);
      if (cb) cb();
    }
    commit("setLoading", false);
  },
  async getEducation({ commit }, id) {
    commit("setLoading", true);
    let fetchEducation = await tapApi.getData(`tap/get-education?id=${id}`);
    if (fetchEducation.data.status == "SUCCESS") {
      commit("setEducation", fetchEducation.data.data[0]);
    }
    commit("setLoading", false);
  },
  async getAllEducationList({ commit }, trainerId) {
    commit("setLoading", true);
    let getAllEducation = await tapApi.getData(
      `tap/get-all-education?trainerId=${trainerId}`
    );
    if (getAllEducation.data.status == "SUCCESS") {
      commit("setEducationList", getAllEducation.data.data);
    }
    commit("setLoading", false);
  },
  async createEducation({ commit, state }, { education, cb }) {
    console.log("educaiton state", education);
    commit("setLoading", true);
    if (education.trainerId) {
      let createEducationResponse = await tapApi.postData(
        "tap/createEducation",
        education
      );
      console.log("create education ", createEducationResponse);
      if (createEducationResponse.data.status == "SUCCESS") {
        let educationList = Object.assign([], state.educationList);
        educationList.unshift(createEducationResponse.data.data[0]);
        commit("setEducationList", educationList);
        commit("setEducation", null);
        if (cb) {
          cb();
        }
      }
    }
    commit("setLoading", false);
  },

  async updateSkillServicesDto({ commit }, trainerSkillServicesDto) {
    commit("setLoading", false);
    let updatedSkillsAndServices = await tapApi.putData(
      "tap/update-trainer-skill-services",
      trainerSkillServicesDto
    );
    console.log("udapted skills and services", updatedSkillsAndServices);
    if (updatedSkillsAndServices.data.status == "SUCCESS") {
      commit(
        "setTrainerSkillServicesDto",
        updatedSkillsAndServices.data.data[0]
      );
    }
  },
  async getSkillsToVerify({ state }, type) {
    let skillService = await Object.assign({}, state.trainerSkillServicesDto);
    if (type == "PRIMARY_SKILLS") {
      if (skillService.primarySkills) {
        return skillService.primarySkills;
      }
    } else if (type == "SECONDARY_SKILLS") {
      if (skillService.secondarySkills) {
        return skillService.secondarySkills;
      }
    } else if (type == "ASPIRATIONAL_SKILLS") {
      if (skillService.aspirationalSkills) {
        return skillService.aspirationalSkills;
      }
    }
  },
  async updateSkills({ state, commit }, { type, skills, cb }) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let updateSkills = await tapApi.putData("tap/update-skills", skills);

    if (updateSkills.data.status == "SUCCESS") {
      let skills = updateSkills.data.data;
      console.log("skillls response  --", skills);
      if (type == "PRIMARY_SKILLS") {
        // remove from primary who have been change and upated secondary or aspirational skills
        skills.forEach((skill) => {
          if (skill.skillType == "SECONDARY_SKILLS") {
            skillService.secondarySkills.push(skill);
          } else if (skill.skillType == "ASPIRATIONAL_SKILLS") {
            skillService.aspirationalSkills.push(skill);
          }
        });
        skills = skills.filter((skill) => skill.skillType == "PRIMARY_SKILLS");
        skillService.primarySkills = skills;
      } else if (type == "SECONDARY_SKILLS") {
        skills.forEach((skill) => {
          if (skill.skillType == "PRIMARY_SKILLS") {
            skillService.primarySkills.push(skill);
          } else if (skill.skillType == "ASPIRATIONAL_SKILLS") {
            skillService.aspirationalSkills.push(skill);
          }
        });
        skills = skills.filter(
          (skill) => skill.skillType == "SECONDARY_SKILLS"
        );
        skillService.secondarySkills = skills;
      } else if (type == "ASPIRATIONAL_SKILLS") {
        skills.forEach((skill) => {
          if (skill.skillType == "PRIMARY_SKILLS") {
            skillService.primarySkills.push(skill);
          } else if (skill.skillType == "SECONDARY_SKILLS") {
            skillService.secondarySkills.push(skill);
          }
        });
        skills = skills.filter(
          (skill) => skill.skillType == "ASPIRATIONAL_SKILLS"
        );
        skillService.aspirationalSkills = skills;
      }
      commit("setTrainerSkillServicesDto", skillService);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  getSkillsAndServiceDelivery({ commit, state }) {
    commit("setLoading", false);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    console.log(
      "skillService.trainerServiceDelivery------",
      skillService.trainerServiceDelivery
    );
    commit("setTrainerSkillSerivices", skillService.trainerServiceDelivery);
  },
  async getSkillServicesDto({ commit }, trainerId) {
    console.log("trainer id get skill service state ", trainerId);
    let getSkillAndDomain = await tapApi.getData(
      `tap/get-trainer-skill-services?trainerId=${trainerId}`
    );
    console.log("skill and domain ", getSkillAndDomain.data.data[0]);
    if (getSkillAndDomain.data.status == "SUCCESS") {
      commit("setTrainerSkillServicesDto", getSkillAndDomain.data.data[0]);
    } else if (getSkillAndDomain.data.status == "NOTFOUND") {
      commit("setTrainerSkillServicesDto", null);
    }
    commit("setLoading", false);
  },
  async createTrainerSkillServicesDTO({ commit, state }, skillTrainer) {
    console.log("trainer ------skillls -------------------", skillTrainer);
    let createResponse = await tapApi.postData(
      "tap/create-trainer-skill-services",
      skillTrainer
    );
    if (createResponse.data.status == "SUCCESS") {
      let editMode = Object.assign({}, state.editMode);
      editMode.skillAndServicesEditMode = true;
      editMode.createskillAndServices = false;
      commit("setEditMode", editMode);
      commit("setTrainerSkillServicesDto", createResponse.data.data[0]);
    }
    console.log("create resposne ", createResponse);
    console.log(state);
    commit("setLoading", false);
  },
  setLoading({ commit }, status) {
    commit("setLoading", status);
  },
  async createSkillsAndServices({ commit, state }, { trainerSkills, cb }) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let trainerSkillAndServices = await tapApi.postData(
      "tap/create-service-delivery",
      trainerSkills
    );
    if (trainerSkillAndServices.data.status == "SUCCESS") {
      skillService.trainerServiceDelivery =
        trainerSkillAndServices.data.data[0];
      commit("setTrainerSkillServicesDto", skillService);
      commit("setTrainerSkillSerivices", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async updateSkillsAndServices({ commit, state }, { trainerSkills, cb }) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let trainerSkillAndServices = await tapApi.putData(
      "tap/update-service-delivery",
      trainerSkills
    );
    if (trainerSkillAndServices.data.status == "SUCCESS") {
      skillService.trainerServiceDelivery =
        trainerSkillAndServices.data.data[0];
      commit("setTrainerSkillServicesDto", skillService);
      commit("setTrainerSkillSerivices", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async createTrainerTargetAudince(
    { commit, state },
    { targetAudience, type, cb }
  ) {
    commit("setLoading", false);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let targetAudienceObject = {
      trainerId: state.profile.trainerId,
      targetAudienceId: targetAudience.id,
      targetAudienceType: type,
      targetAudienceName: targetAudience.targetAudienceName,
      trainerVerifiedTargetAudeince: false,
      dueDeligenceVerifiedTargetAudeince: false
    };
    let createTargetAudince = await tapApi.postData(
      "tap/create-target-audince",
      targetAudienceObject
    );
    if (createTargetAudince.data.status == "SUCCESS") {
      if (type == "PRIMARY_SKILLS_TARGET_AUDIENCE") {
        skillService.primarySkillsTargetAudience =
          createTargetAudince.data.data[0];
      } else if (type == "ASPIRATIONAL_SKILLS_TARGET_AUDIENCE") {
        skillService.aspirationalSkillsTargetAudience =
          createTargetAudince.data.data[0];
      } else if (type == "SECONDARY_SKILLS_TARGET_AUDIENCE") {
        skillService.secondarySkillsTargetAudience =
          createTargetAudince.data.data[0];
      }
      if (cb) {
        cb();
      }
    }
    commit("setTrainerSkillServicesDto", skillService);
  },
  ///update-target-audince
  async updateTargetAudience({ commit, state }, { targetAudience, type, cb }) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let id = null;
    if (type == "PRIMARY_SKILLS_TARGET_AUDIENCE") {
      id = skillService.primarySkillsTargetAudience.id;
    } else if (type == "ASPIRATIONAL_SKILLS_TARGET_AUDIENCE") {
      id = skillService.aspirationalSkillsTargetAudience.id;
    } else if (type == "SECONDARY_SKILLS_TARGET_AUDIENCE") {
      id = skillService.secondarySkillsTargetAudience.id;
    }
    let targetAudienceObject = {
      id: id,
      trainerId: state.profile.trainerId,
      targetAudienceId: targetAudience.id,
      targetAudienceType: type,
      targetAudienceName: targetAudience.targetAudienceName,
      trainerVerifiedTargetAudeince: false,
      dueDeligenceVerifiedTargetAudeince: false
    };
    let updateTargetAudince = await tapApi.putData(
      "tap/update-target-audience",
      targetAudienceObject
    );
    if (updateTargetAudince.data.status == "SUCCESS") {
      if (type == "PRIMARY_SKILLS_TARGET_AUDIENCE") {
        skillService.primarySkillsTargetAudience =
          updateTargetAudince.data.data[0];
      } else if (type == "ASPIRATIONAL_SKILLS_TARGET_AUDIENCE") {
        skillService.aspirationalSkillsTargetAudience =
          updateTargetAudince.data.data[0];
      } else if (type == "SECONDARY_SKILLS_TARGET_AUDIENCE") {
        skillService.secondarySkillsTargetAudience =
          updateTargetAudince.data.data[0];
      }
      if (cb) {
        cb();
      }
    }
    commit("setTrainerSkillServicesDto", skillService);
    commit("setLoading", false);
  },
  async createTrainerToolsAndPlatfrom(
    { commit, state },
    { trainerTools, type, cb }
  ) {
    console.log("trainer tools and platfrom in state", trainerTools);
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    // make an api call then push to repective sate
    let createToolsAndPlatfrom = await tapApi.postData(
      "tap/create-trainer-tools",
      trainerTools
    );
    console.log("repsonse ", createToolsAndPlatfrom);
    if (createToolsAndPlatfrom.data.status == "SUCCESS") {
      if (type == "TOOLSANDPLATFORM") {
        skillService.toolsAndPlatFrom.push(createToolsAndPlatfrom.data.data[0]);
      } else if (type == "TARGETINDUSTRY") {
        skillService.targetIndustryAndDepartment.push(
          createToolsAndPlatfrom.data.data[0]
        );
      } else if (type == "TARGETDEPARTMENT") {
        skillService.targetDepartment.push(createToolsAndPlatfrom.data.data[0]);
      }
      commit("setTrainerSkillServicesDto", skillService);
      if (cb) {
        cb();
        console.log("cb calleed");
      }
      commit("setToolAndPlatfrom", null);
    }
    commit("setLoading", false);
  },
  async updateToolsAndPlatfrom(
    { commit, state },
    { trainerTools, type, index, cb }
  ) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    let updateToolsAndPlatfrom = await tapApi.putData(
      "tap/update-trainer-tools",
      trainerTools
    );
    console.log("update tools and paltfrom ", updateToolsAndPlatfrom);
    if (updateToolsAndPlatfrom.data.status == "SUCCESS") {
      if (type == "TOOLSANDPLATFORM") {
        skillService.toolsAndPlatFrom[index] =
          updateToolsAndPlatfrom.data.data[0];
      } else if (type == "TARGETINDUSTRY") {
        skillService.targetIndustryAndDepartment[index] =
          updateToolsAndPlatfrom.data.data[0];
      } else if (type == "TARGETDEPARTMENT") {
        skillService.targetDepartment[index] =
          updateToolsAndPlatfrom.data.data[0];
      }
      commit("setToolAndPlatfrom", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
    commit("setTrainerSkillServicesDto", skillService);
  },
  getToolsAndPlatfrom({ commit, state }, { type, index }) {
    commit("setLoading", false);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    if (type == "TOOLSANDPLATFORM") {
      let tools = skillService.toolsAndPlatFrom[index];
      commit("setToolAndPlatfrom", tools);
    } else if (type == "TARGETINDUSTRY") {
      let tools = skillService.targetIndustryAndDepartment[index];
      commit("setToolAndPlatfrom", tools);
    } else if (type == "TARGETDEPARTMENT") {
      let tools = skillService.targetDepartment[index];
      commit("setToolAndPlatfrom", tools);
    }
  },
  deleteSkill({ commit, state }, skillEntity) {
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    if (
      skillEntity.skillType == "PRIMARY_SKILLS" ||
      skillEntity.skillType == "PRIMARY_SKILLS_NON_INVENTORY"
    ) {
      skillService.primarySkills = skillService.primarySkills.filter(
        (skill) => skill.skillName != skillEntity.skillName
      );
    } else if (
      skillEntity.skillType == "SECONDARY_SKILLS" ||
      skillEntity.skillType == "SECONDARY_SKILLS_NON_INVENTORY"
    ) {
      console.log("delete secondary skills");
      skillService.secondarySkills = skillService.secondarySkills.filter(
        (skill) => skill.skillName != skillEntity.skillName
      );
    } else if (
      skillEntity.skillType == "ASPIRATIONAL_SKILLS" ||
      skillEntity.skillType == "ASPIRATIONAL_SKILLS_NON_INVENTORY"
    ) {
      console.log("delete secondary skills");
      skillService.aspirationalSkills = skillService.aspirationalSkills.filter(
        (skill) => skill.skillName != skillEntity.skillName
      );
    }
    commit("setTrainerSkillServicesDto", skillService);
  },
  resetTrainerSkills({ commit }) {
    commit("setTrainerSkills", null);
  },
  async createTrainerSkills({ commit, state }, { skill, type, cb }) {
    commit("setLoading", true);
    let skillService = Object.assign({}, state.trainerSkillServicesDto);
    if (type == "PRIMARY_SKILLS") {
      skillService.primarySkills = skill;
    } else if (type == "SECONDARY_SKILLS") {
      skillService.secondarySkills = skill;
    } else if (type == "ASPIRATIONAL_SKILLS") {
      skillService.aspirationalSkills = skill;
    }
    if (!skillService.trainerId) {
      skillService.trainerId = state.profile.trainerId;
    }
    let updateSkillResponse = await tapApi.putData(
      "tap/update-trainer-skill-services",
      skillService
    );
    if (updateSkillResponse.data.status == "SUCCESS") {
      commit("setTrainerSkillServicesDto", skillService);
      commit("setTrainerSkills", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  // get all target Audeince
  async getAllTargetAudience({ commit }) {
    commit("setLoading", false);
    let targetAudience = await tapApi.getData("tap/getAllTargetAudince");
    console.log("response for target audience ", targetAudience);
    return targetAudience.data.data;
  },
  // create guest faculty
  async createGuestFaculty({ state, commit }, { guestFaculty, cb }) {
    commit("setLoading", true);
    let guestFacultyExp = await tapApi.postData(
      "tap/addGuestFacultyExp",
      guestFaculty
    );
    if (guestFacultyExp.data.status == "SUCCESS") {
      let guestFacultyList = Object.assign([], state.guestFauculyList);
      guestFacultyList.push(guestFacultyExp.data.data[0]);
      commit("setGuestFacultyList", guestFacultyList);
      commit("setGuestFacultyExperince", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async updateGuestFaclty({ commit, state }, { guestFaculty, cb }) {
    commit("setLoading", true);
    let updatedGuestFaculty = await tapApi.putData(
      "tap/updateGuestFacultyExperince",
      guestFaculty
    );
    if (updatedGuestFaculty.data.status == "SUCCESS") {
      let guestFacultyList = Object.assign([], state.guestFauculyList);
      // filter and update
      guestFacultyList.forEach((guestFacultyExp) => {
        if (guestFacultyExp.id == updatedGuestFaculty.data.data[0].id) {
          (guestFacultyExp.id = updatedGuestFaculty.data.data[0].id),
            (guestFacultyExp.trainerId =
              updatedGuestFaculty.data.data[0].trainerId),
            (guestFacultyExp.instituteName =
              updatedGuestFaculty.data.data[0].instituteName),
            (guestFacultyExp.designationName =
              updatedGuestFaculty.data.data[0].designationName),
            (guestFacultyExp.numberOfSessionDelivered =
              updatedGuestFaculty.data.data[0].numberOfSessionDelivered),
            (guestFacultyExp.description =
              updatedGuestFaculty.data.data[0].description),
            (guestFacultyExp.keySkills =
              updatedGuestFaculty.data.data[0].keySkills);
          guestFacultyExp.startDate =
            updatedGuestFaculty.data.data[0].startDate;
          guestFacultyExp.endDate = updatedGuestFaculty.data.data[0].endDate;
        }
      });
      commit("setGuestFacultyList", guestFacultyList);
      commit("setGuestFacultyExperince", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async getAllGuestFaculty({ commit }, trainerId) {
    commit("setLoading", true);
    let guestFacultyList = await tapApi.getData(
      `tap/getAllGuestFacultyExperince?trainerId=${trainerId}`
    );
    if (guestFacultyList.data.status == "SUCCESS") {
      commit("setGuestFacultyList", guestFacultyList.data.data);
    }
  },
  async getGuestFaculty({ commit }, id) {
    commit("setLoading", true);
    let guestFaculty = await tapApi.getData(
      `tap/getGuestFacultyExperince?id=${id}`
    );
    if (guestFaculty.data.status == "SUCCESS") {
      commit("setGuestFacultyExperince", guestFaculty.data.data[0]);
    }
    commit("setLoading", false);
  },

  // update addmin comemtns to legal data
 async changeEditMode({ state, commit }, pageType) {
    let editMode = Object.assign({}, state.editMode);
    console.log("edit mode  ----", editMode);
    if (pageType == "UPDATE_TRAINER") {
      // copy the object and
      editMode.trainerProfile = true;
    } else if (pageType == "UPDATE_ADMIN_CONSOLE") {
      editMode.adminConsole = true;
    } else if (pageType == "ORIGINAL_FILES") {
      editMode.originalFiles = true;
    } else if (pageType == "PAYMENT_DETIALS") {
      editMode.paymentDetails = true;
    } else if (pageType == "LEGAL") {
      editMode.legal = true;
    }
    console.log("eddmiot mode updateed ----", editMode);

    await commit("setEditMode", editMode);
    return editMode;
  },
  // search primary skills
  async serachPrimarySkills({ commit }, val) {
    commit("setLoading", false);
    console.log("value to be searhed state", val);
    // do the api  call
    let searchResponse = await tapApi.getData(
      `skill-db/searchSkill?search=${val}`
    );
    console.log("Response from search api ", searchResponse.data.data);
    return searchResponse.data.data;
  },
  createAdminCommnets({ state, commit, rootState }, adminComments) {
    commit("setLoading", false);
    let user = rootState.userAuthenticaiton.user;
    console.log("root sttate  ---", user);
    console.log("admin comments  ---", adminComments);
    adminComments.userName = user.firstName + " " + user.lastName;
    if (adminComments.type == "LEGAL") {
      console.log("state if it is legal  ", state);
      let legal = Object.assign({}, state.legal);
      legal.listOfLegalExcetpions.push(adminComments);
      console.log("legal state  --after adddmin addmin commnets ", legal);
      commit("setLegal", legal);
      // reset sate of amdin commnets
      commit("setAdminComments", null);
    }
  },
  // create a legal
  createLegalDetiails({ commit }, legalDetails) {
    commit("setLoading", true);
    tapApi
      .postData(`tap/addLegalInformation`, legalDetails)
      .then((res) => {
        console.log("from get leagal details ---", res);
        if (res.data.status === "SUCCESS") {
          commit("setLegal", res.data.data[0]);
          commit("setEditMode", null);
        } else {
          commit("setLegal", null);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updateLegalDetials({ commit }, updatedLegalDetails) {
    commit("setLoading", true);
    console.log("updated legel detials", updatedLegalDetails);
    tapApi
      .putData(`tap/updateLegalDetials`, updatedLegalDetails)
      .then((res) => {
        console.log("from get leagal details ---", res);
        if (res.data.status === "SUCCESS") {
          commit("setLegal", res.data.data[0]);
          commit("setEditMode", null);
        } else {
          commit("setLegal", null);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  async updateWorkEx({ commit, state }, { workx, cb }) {
    commit("setLoading", true);
    let workxList = Object.assign([], state.workExpList);

    try {
      let upatedWorkEx = await tapApi.putData(`tap/updateWorkEx`, workx);
      if (upatedWorkEx.data.status == "SUCCESS") {
        workxList.forEach((workEx) => {
          if (workEx.id == upatedWorkEx.data.data[0].id) {
            workEx.companyName = upatedWorkEx.data.data[0].companyName;
            workEx.designation = upatedWorkEx.data.data[0].designation;
            workEx.startDate = upatedWorkEx.data.data[0].startDate;
            workEx.endDate = upatedWorkEx.data.data[0].endDate;
            workEx.rolesAndResponsibilites =
              upatedWorkEx.data.data[0].rolesAndResponsibilites;
            workEx.department = upatedWorkEx.data.data[0].department;
            workEx.natureOfEngagement =
              upatedWorkEx.data.data[0].natureOfEngagement;
            workEx.industry = upatedWorkEx.data.data[0].industry;
            workEx.keySkills = upatedWorkEx.data.data[0].keySkills;
            workEx.keyTools = upatedWorkEx.data.data[0].keyTools;
          }
          if (cb) {
            cb();
          }
        });
        commit("setWorkExpList", workxList);
        console.log("updated work ex", upatedWorkEx.data.data[0]);
        commit("setLoading", false);
        commit("setWorkEx", null);
      }
    } catch (e) {
      console.log("error", e);
    }
  },
  // get work ex
  async getAWorkEx({ commit }, id) {
    commit("setLoading", true);
    try {
      let getAWorkEx = await tapApi.getData(`tap/getWorkEx?id=${id}`);
      if (getAWorkEx.data.status == "SUCCESS") {
        console.log(" work ex", getAWorkEx.data.data[0]);
        commit("setLoading", false);
        commit("setWorkEx", getAWorkEx.data.data[0]);
      }
    } catch (e) {
      commit("setLoading", false);
      commit("setLoading", false);
      console.log(e);
    }
  },
  // get all work ex
  async getAllWorkEx({ commit }, trainerId) {
    commit("setLoading", true);
    try {
      let allWorkEx = await tapApi.getData(
        `tap/getAllWorkEx?trainerId=${trainerId}`
      );
      if (allWorkEx.data.status == "SUCCESS") {
        console.log("all all Work Ex ---", allWorkEx.data.data);
        commit("setLoading", false);
        commit("setWorkExpList", allWorkEx.data.data);
        return allWorkEx.data.data;
      } else {
        commit("setLoading", false);
      }
    } catch (e) {
      console.log(e);
      Store.dispatch(
        "project/sendErrorNotice",
        "Feature Failed! Will fix soon."
      );
      commit("setLoading", false);
    }
  },
  // update work ex

  // create work ex
  createWorkEx({ commit, state }, { workEx, cb }) {
    commit("setLoading", true);
    tapApi
      .postData("tap/addWorkEx", workEx)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          // set to work ex list
          let workxList = Object.assign([], state.workExpList);
          workxList.unshift(res.data.data[0]);
          commit("setWorkExpList", workxList);
          console.log("work ex  ---", res.data);
          commit("setLoading", false);
          commit("setWorkEx", null);
        } else {
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
        if (cb) cb();
      });
  },
  getOriginalFiles({ commit }, trainerId) {
    commit("setLoading", true);

    tapApi
      .getData(`tap/getOriginalFiles?trainerId=${trainerId}`)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          commit("setLoading", false);
          commit("setOrignalFiles", res.data.data[0]);
        } else {
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updateOrginalFiles({ commit }, originalFiles) {
    commit("setLoading", true);
    tapApi
      .putData("tap/updateOriginalFiles", originalFiles)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          commit("setEditMode", null);
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Trainer related files updated",
            headerText: "Sucessful",
            afterOk: () => {}
          });
          commit("setLoading", false);
          commit("setOrignalFiles", res.data.data[0]);
        } else {
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Unable to update trainer related files",
            headerText: "Failed",
            afterOk: () => {}
          });
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  createOriginalFiles({ commit }, originalFiles) {
    commit("setLoading", true);
    tapApi
      .postData("tap/addOriginalFiles", originalFiles)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Trainer related files created",
            headerText: "Sucessful",
            afterOk: () => {}
          });
          commit("setEditMode", null);
          commit("setLoading", false);
          commit("setOrignalFiles", res.data.data[0]);
        } else {
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Failed to update trainer related files",
            headerText: "Failed",
            afterOk: () => {}
          });
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updatePaymentDetails({ commit }, paymentDetials) {
    commit("setLoading", true);
    tapApi
      .putData(`tap/updatePaymentDetails`, paymentDetials)
      .then((res) => {
        console.log("get trainer details ==", res);
        if (res.data.status === "SUCCESS") {
          commit("setPaymentDetails", res.data.data[0]);
          commit("setEditMode", null);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getPaymentDetails({ commit }, trainerId) {
    commit("setLoading", true);
    tapApi
      .getData(`tap/getPaymentDetails?trainerId=${trainerId}`)
      .then((res) => {
        console.log("get trainer details ==", res);
        if (res.data.status === "SUCCESS") {
          commit("setPaymentDetails", res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  createPaymentDetials({ commit }, paymentDetials) {
    commit("setLoading", true);
    tapApi
      .postData("tap/addPaymentDetiails", paymentDetials)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          commit("setEditMode", null);
          commit("setPaymentDetails", res.data.data[0]);
        } else {
          commit("setPaymentDetails", null);
          console.log("failed to save");
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  deleteAFile({ commit, state }, { type, fileUrl, deleteConfirmation }) {
    if (deleteConfirmation) {
      commit("setDelelteAlertDailog", false);
      commit("setLoading", true);
      let formData = new FormData();
      formData.append("fileUrl", fileUrl);
      tapApi.postData("tap/deleteFile", formData).then((res) => {
        if (res.data.status == "SUCCESS") {
          if (type == "ORIGINAL_PROFILE") {
            let orginaFiles = Object.assign({}, state.trainerOriginalFiles);
            orginaFiles.trainerProfileUrl.splice(
              orginaFiles.trainerProfileUrl.indexOf(fileUrl),
              1
            );
            commit("setOrignalFiles", orginaFiles);
          } else if (type == "ORIGINAL_TEMPLATE") {
            let orginaFiles = Object.assign({}, state.trainerOriginalFiles);
            orginaFiles.trainerDetailedTemplateUrl.splice(
              orginaFiles.trainerDetailedTemplateUrl.indexOf(fileUrl),
              1
            );
            commit("setOrignalFiles", orginaFiles);
          } else if (type == "LEGEAL") {
            let legal = Object.assign({}, state.legal);
            legal.legalDocumentUrl = "";
            commit("setLegal", legal);
          }
        }
      });
      commit("setLoading", false);
    } else {
      commit("setDelelteAlertDailog", true);
      commit("setAfterOk", {
        type: type,
        fileUrl: fileUrl
      });
    }
  },
  downloadAFile({ commit }, { type, index, fileUrl }) {
    console.log("type  ---", type, index);
    console.log("file url in state ", fileUrl);
    commit("setLoading", true);
    let url = "";
    if (type == Constants.FILE_TYPE_PAN) {
      url =
        Store.state.trainerProfile.paymentDetials.trainerPanDetials.panFileUrl;
    } else if (type == Constants.FILE_TYPE_GST) {
      url =
        Store.state.trainerProfile.paymentDetials.trainerGst.gstCertificateUrl;
    } else if (type == Constants.FILE_TYPE_BANK) {
      url =
        Store.state.trainerProfile.paymentDetials.trainerBankDetials
          .cancelledChequeUrl;
    } else if (type == Constants.FILE_TYPE_COMPANY_PAN) {
      url =
        Store.state.trainerProfile.paymentDetials.trainerCompanyDetails
          .companyPanFileUrl;
    } else if (type == Constants.FILE_TYPE_COMPANY_MSME) {
      url =
        Store.state.trainerProfile.paymentDetials.trainerCompanyDetails
          .msmeCertificateUrl;
    } else if (type == Constants.FILE_TYPE_ORIGINAL_FILES_PROFILE) {
      //url = Store.state.trainerProfile.trainerOriginalFiles.trainerProfileUrl;
      url = fileUrl;
    } else if (type == Constants.FILE_TYPE_ORIGINAL_FILES_TEMPLATE) {
      url = fileUrl;
      // url =
      //   Store.state.trainerProfile.trainerOriginalFiles
      //     .trainerDetailedTemplateUrl;
    } else if (type == Constants.FILE_TYPE_COURSE_UPLOAD) {
      console.log("-----------file type course upload ---------");
      console.log("index ", index);
      if (index >= 0) {
        console.log("course list ", Store.state.trainerProfile.coursesList);
        url = Store.state.trainerProfile.coursesList[index].courseOutlineUrl;
      } else {
        url = Store.state.trainerProfile.topCourse.courseOutlineUrl;
      }
    } else if (type == Constants.FILE_TYPE_TESTIMONIAL_UPLOAD) {
      if (index >= 0) {
        url =
          Store.state.trainerProfile.testimonialList[index]
            .testimonialAttachementUrl;
      } else {
        url = Store.state.trainerProfile.testimonial.testimonialAttachementUrl;
      }
    } else if (type == Constants.FILE_TYPE_REFERENCES_UPLOAD) {
      if (index >= 0) {
        url =
          Store.state.trainerProfile.referencesList[index]
            .referenceAttachmentUrl;
      } else {
        url = Store.state.trainerProfile.reference.referenceAttachmentUrl;
      }
    }
    console.log("url to dowload----", url);
    if (url === null || url === "") return;
    tapApi
      .downloadData("tap/downloadFromS3?url=" + url)
      .then((res) => {
        console.log("download successfull");
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlDownload;
        let fileName = url.substring(83);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        commit("setLoading", false);
      })
      .catch((err) => {
        console.log("error ocuured in profile pic download");
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      });
    //
  },
  uploadFile({ commit }, { data, trainerId, cb, type }) {
    console.log("type  ---", type);
    console.log("inside file Upload");
    let fileType = "";
    let s3FolderUrl = "";

    if (type == Constants.FILE_TYPE_PAN) {
      fileType = Constants.FILE_TYPE_PAN;
      s3FolderUrl = Constants.PAN_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_GST) {
      fileType = Constants.FILE_TYPE_GST;
      s3FolderUrl = Constants.GST_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_BANK) {
      fileType = Constants.FILE_TYPE_BANK;
      s3FolderUrl = Constants.BANK_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_COMPANY_PAN) {
      fileType = Constants.FILE_TYPE_COMPANY_PAN;
      s3FolderUrl = Constants.COMPANY_PAN_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_COMPANY_MSME) {
      fileType = Constants.FILE_TYPE_COMPANY_MSME;
      s3FolderUrl = Constants.COMPANY_MSME_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_ORIGINAL_FILES_PROFILE) {
      fileType = Constants.FILE_TYPE_ORIGINAL_FILES_PROFILE;
      s3FolderUrl = Constants.ORIGINAL_FILES_PROFILE_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_ORIGINAL_FILES_TEMPLATE) {
      fileType = Constants.FILE_TYPE_ORIGINAL_FILES_TEMPLATE;
      s3FolderUrl = Constants.ORIGINAL_FILES_TEMPLATE_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_COURSE_UPLOAD) {
      fileType = Constants.FILE_TYPE_COURSE_UPLOAD;
      s3FolderUrl = Constants.COURSE_UPLOAD_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_TESTIMONIAL_UPLOAD) {
      fileType = Constants.FILE_TYPE_TESTIMONIAL_UPLOAD;
      s3FolderUrl = Constants.TESTIMONIAL_UPLOAD_FOLDER_NAME_IN_S3;
    } else if (type == Constants.FILE_TYPE_REFERENCES_UPLOAD) {
      fileType = Constants.FILE_TYPE_REFERENCES_UPLOAD;
      s3FolderUrl = Constants.REFERENCES_UPLOAD_FOLDER_NAME_IN_S3;
    }
    if (data) {
      commit("setLoading", true);
      let formData = new FormData();
      formData.append("file", data);
      formData.append("trainerId", trainerId);
      tapApi
        .uploadData(`tap/uploadToS3/${s3FolderUrl}`, formData)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            // save to Files api
            console.log("file upload sucessful");
            let trainerFile = Store.state.trainerProfile.trainerFiles;
            trainerFile.trainerFileType = fileType;
            trainerFile.trainerId = trainerId;
            trainerFile.trainerFileUrl = res.data.data[0];
            console.log("trainer files  ---", trainerFile);
            Store.dispatch("trainerProfile/createFile", trainerFile);
          } else {
            Store.dispatch("project/dealUnsuccessResponse", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          Store.dispatch(
            "project/sendErrorNotice",
            "Feature Failed! Will fix soon."
          );
        })
        .finally(() => {
          commit("setLoading", false);
          if (cb) cb();
        });
    } else {
      console.log(
        "---------------------------------do nothing just chilll------------------------"
      );
    }
  },
  //get legal
  getLegalDetials({ commit }, trainerId) {
    tapApi
      .getData(`tap/getLegalDetials?trainerId=${trainerId}`)
      .then((res) => {
        console.log("create legal detials details ---", res);
        if (res.data.status === "SUCCESS") {
          commit("setLegal", res.data.data[0]);
          // Store.dispatch('trainerProfile/downloadLegalFile')
        } else {
          commit("setLegal", null);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  downloadLegalFile({ commit }) {
    commit("setLoading", true);
    const url = Store.state.trainerProfile.legal.legalDocumentUrl;
    console.log("url from legal state ----", url);
    if (url === null || url === "") return;
    tapApi
      .downloadData("tap/downloadFromS3?url=" + url)
      .then((res) => {
        console.log("download successfull");
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlDownload;
        let fileName = url.substring(83);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        commit("setLoading", false);
      })
      .catch((err) => {
        console.log("error ocuured in profile pic download");
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      });
  },

  // createFile
  createFile({ commit }, trainerFiles) {
    commit("setLoading", true);
    console.log("inside create files", trainerFiles);
    tapApi
      .postData("tap/addFiles", trainerFiles)
      .then((res) => {
        console.log("response from file", trainerFiles);
        if (res.data.status === "SUCCESS") {
          let { trainerFileType, trainerFileUrl } = res.data.data[0];
          console.log("trainer file type ", trainerFileType, trainerFileUrl);
          if (trainerFileType == Constants.FILE_TYPE_LEGAL) {
            let legal = Store.state.trainerProfile.legal;
            legal.legalDocumentUrl = trainerFileUrl;
            console.log("legal  ---", legal);
            commit("setLegal", legal);
          } else if (trainerFileType == Constants.FILE_TYPE_PAN) {
            let paymentDetials = Store.state.trainerProfile.paymentDetials;
            paymentDetials.trainerPanDetials.panFileUrl = trainerFileUrl;
            commit("setPaymentDetails", paymentDetials);
          } else if (trainerFileType == Constants.FILE_TYPE_BANK) {
            let paymentDetials = Store.state.trainerProfile.paymentDetials;
            paymentDetials.trainerBankDetials.cancelledChequeUrl = trainerFileUrl;
            commit("setPaymentDetails", paymentDetials);
          } else if (trainerFileType == Constants.FILE_TYPE_GST) {
            let paymentDetials = Store.state.trainerProfile.paymentDetials;
            paymentDetials.trainerGst.gstCertificateUrl = trainerFileUrl;
            commit("setPaymentDetails", paymentDetials);
          } else if (trainerFileType == Constants.FILE_TYPE_COMPANY_MSME) {
            let paymentDetials = Store.state.trainerProfile.paymentDetials;
            paymentDetials.trainerCompanyDetails.msmeCertificateUrl = trainerFileUrl;
            commit("setPaymentDetails", paymentDetials);
          } else if (trainerFileType == Constants.FILE_TYPE_COMPANY_PAN) {
            let paymentDetials = Store.state.trainerProfile.paymentDetials;
            paymentDetials.trainerCompanyDetails.companyPanFileUrl = trainerFileUrl;
            commit("setPaymentDetails", paymentDetials);
          } else if (
            trainerFileType == Constants.FILE_TYPE_ORIGINAL_FILES_PROFILE
          ) {
            let orignalFiles = Store.state.trainerProfile.trainerOriginalFiles;
            orignalFiles.trainerProfileUrl.unshift(trainerFileUrl);
            commit("setOrignalFiles", orignalFiles);
          } else if (
            trainerFileType == Constants.FILE_TYPE_ORIGINAL_FILES_TEMPLATE
          ) {
            let orignalFiles = Store.state.trainerProfile.trainerOriginalFiles;
            orignalFiles.trainerDetailedTemplateUrl.unshift(trainerFileUrl);
            commit("setOrignalFiles", orignalFiles);
          } else if (trainerFileType == Constants.FILE_TYPE_COURSE_UPLOAD) {
            console.log("top course");
            let topCourse = Store.state.trainerProfile.topCourse;
            topCourse.courseOutlineUrl = trainerFileUrl;
            commit("setTopCourse", topCourse);
          } else if (
            trainerFileType == Constants.FILE_TYPE_TESTIMONIAL_UPLOAD
          ) {
            let testimonial = Store.state.trainerProfile.testimonial;
            testimonial.testimonialAttachementUrl = trainerFileUrl;
            commit("setTestimonial", testimonial);
          } else if (trainerFileType == Constants.FILE_TYPE_REFERENCES_UPLOAD) {
            console.log("inside references");
            let reference = Store.state.trainerProfile.reference;
            reference.referenceAttachmentUrl = trainerFileUrl;
            commit("setReference", reference);
          }
          commit("setTrainerFiles", null);
          // if (type == Constants.FILE_TYPE_PAN) {
          //   fileType = Constants.FILE_TYPE_PAN
          //   s3FolderUrl=Constants.PAN_FOLDER_NAME_IN_S3
          // } else if (type == Constants.FILE_TYPE_GST) {
          //   fileType = Constants.FILE_TYPE_GST
          //   s3FolderUrl = Constants.GST_FOLDER_NAME_IN_S3
          // } else if (type == Constants.FILE_TYPE_BANK) {
          //   fileType = Constants.FILE_TYPE_BANK
          //   s3FolderUrl = Constants.BANK_FOLDER_NAME_IN_S3
          // } else if (type == Constants.FILE_TYPE_COMPANY_PAN) {
          //   fileType = Constants.FILE_TYPE_COMPANY_PAN
          //   s3FolderUrl=Constants.COMPANY_PAN_FOLDER_NAME_IN_S3

          // } else if (type == Constants.FILE_TYPE_COMPANY_MSME) {
          //   fileType = Constants.FILE_TYPE_COMPANY_MSME
          //   s3FolderUrl=Constants.COMPANY_MSME_FOLDER_NAME_IN_S3
          // }
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  // upload a legal files
  uploadLegalFile({ commit }, { data, trainerId, cb }) {
    console.log("inside uplod legal");
    if (data) {
      commit("setLoading", true);
      let formData = new FormData();
      formData.append("file", data);
      formData.append("trainerId", trainerId);
      tapApi
        .uploadData(
          `tap/uploadToS3/${Constants.LEGAL_FOLDER_NAME_IN_S3}`,
          formData
        )
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            // save to Files api
            console.log("file upload sucessful");
            let trainerFile = Store.state.trainerProfile.trainerFiles;
            trainerFile.trainerFileType = Constants.FILE_TYPE_LEGAL;
            trainerFile.trainerId = trainerId;
            trainerFile.trainerFileUrl = res.data.data[0];
            console.log("trainer files  ---", trainerFile);
            Store.dispatch("trainerProfile/createFile", trainerFile);
          } else {
            Store.dispatch("project/dealUnsuccessResponse", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          Store.dispatch(
            "project/sendErrorNotice",
            "Feature Failed! Will fix soon."
          );
        })
        .finally(() => {
          commit("setLoading", false);
          if (cb) cb();
        });
    } else {
      console.log(
        "---------------------------------do nothing just chilll------------------------"
      );
    }
  },
  updateACourse({ commit, state }, { course, cb }) {
    commit("setLoading", true);
    let courseList = Object.assign([], state.coursesList);
    tapApi
      .putData("tap/updateCourse", course)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          commit("setLoading", false);
          courseList.forEach((course) => {
            if (course.id == res.data.data[0].id) {
              course.courseName = res.data.data[0].courseName;
              course.courseComplexity = res.data.data[0].courseComplexity;
              course.targetAudienceDesignation =
                res.data.data[0].targetAudienceDesignation;
              course.tragetIndustry = res.data.data[0].tragetIndustry;
              course.targetDepartment = res.data.data[0].targetDepartment;
              course.minimumFees = res.data.data[0].minimumFees;
              course.standardFees = res.data.data[0].standardFees;
              course.associatedCertification =
                res.data.data[0].associatedCertification;
              course.associatedAwards = res.data.data[0].associatedAwards;
              course.associatedPatent = res.data.data[0].associatedPatent;
              course.associatedPublications =
                res.data.data[0].associatedPublications;
              course.trainingsDeliveredForCourse =
                res.data.data[0].trainingsDeliveredForCourse;
              course.sessionDeliveredForCourse =
                res.data.data[0].sessionDeliveredForCourse;
              course.keySkillOfCourse = res.data.data[0].keySkillOfCourse;
              course.keyCleintsCourse = res.data.data[0].keyCleintsCourse;
              course.courseSKillsconsultingExperince =
                res.data.data[0].courseSKillsconsultingExperince;
              course.appliedSkillInCorporate =
                res.data.data[0].appliedSkillInCorporate;
              course.courseOutlineUrl = res.data.data[0].courseOutlineUrl;
            }
          });
          commit("setCoursesList", courseList);
          commit("setTopCourse", null);
        } else {
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
        if (cb) cb();
      });
  },
  async getACourse({ commit }, courseId) {
    commit("setLoading", true);
    console.log("course id", courseId);
    let getCourse = await tapApi.getData(`tap/getCourse?id=${courseId}`);
    try {
      getCourse.data.data[0].associatedAwards = Object.values(
        getCourse.data.data[0].associatedAwards
      )[0];
      getCourse.data.data[0].associatedCertification = Object.values(
        getCourse.data.data[0].associatedCertification
      )[0];
      getCourse.data.data[0].associatedPatent = Object.values(
        getCourse.data.data[0].associatedPatent
      )[0];
      getCourse.data.data[0].associatedPublications = Object.values(
        getCourse.data.data[0].associatedPublications
      )[0];
    } catch (e) {
      commit("setLoading", false);
    }
    commit("setTopCourse", getCourse.data.data[0]);
    commit("setLoading", false);
  },
  async getAllCourses({ commit }, trainerId) {
    commit("setLoading", true);
    try {
      let coursesList = await tapApi.getData(
        `tap/getAllCourse?trainerId=${trainerId}`
      );
      if (coursesList.data.status == "SUCCESS") {
        console.log("all courses  ---", coursesList.data.data);
        commit("setLoading", false);
        commit("setCoursesList", coursesList.data.data);
        return coursesList.data.data;
      } else {
        commit("setLoading", false);
      }
    } catch (e) {
      console.log(e);
      Store.dispatch(
        "project/sendErrorNotice",
        "Feature Failed! Will fix soon."
      );
      commit("setLoading", false);
    }
  },
  createCourse({ commit, state }, { course, cb }) {
    let courseList = Object.assign([], state.coursesList);
    commit("setLoading", true);
    tapApi
      .postData("tap/addCourses", course)
      .then((res) => {
        if (res.data.status == "SUCCESS") {
          commit("setLoading", false);
          courseList.unshift(res.data.data[0]);
          commit("setCoursesList", courseList);
          commit("setTopCourse", null);
          Router.push("/pages/topcourse");
        } else {
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
        if (cb) cb();
      });
  },
  // get patent
  async getAPatent({ commit }, patentId) {
    commit("setLoading", true);
    let getPatent = await tapApi.getData(`tap/getPatent?id=${patentId}`);
    if (getPatent.data.status == "SUCCESS") {
      commit("setPatent", getPatent.data.data[0]);
    }
    commit("setLoading", false);
  },
  //get publication
  async getAPublicaiton({ commit }, publicationId) {
    commit("setLoading", true);
    let getPublicaiton = await tapApi.getData(
      `tap/getPublication?id=${publicationId}`
    );
    if (getPublicaiton.data.status == "SUCCESS") {
      commit("setPublication", getPublicaiton.data.data[0]);
    }
    commit("setLoading", false);
  },
  // get a award
  async getAward({ commit }, awardId) {
    commit("setLoading", true);
    let getAward = await tapApi.getData(`tap/getAward?id=${awardId}`);
    if (getAward.data.status == "SUCCESS") {
      commit("setAward", getAward.data.data[0]);
    }
    commit("setLoading", false);
  },
  // update patent
  async updatePatent({ state, commit }, { cb, patent }) {
    commit("setLoading", true);
    let patentList = state.patentList;
    let updatedPatent = await tapApi.putData("tap/updatePatent", patent);
    if (updatedPatent.data.status == "SUCCESS") {
      patentList.forEach((patent) => {
        if (patent.id == updatedPatent.data.data[0].id) {
          patent.patentName = updatedPatent.data.data[0].patentName;
          patent.patentNumber = updatedPatent.data.data[0].patentNumber;
          patent.yearGranted = updatedPatent.data.data[0].yearGranted;
        }
      });
    }
    commit("setPatentList", patentList);
    commit("setPatent", null);
    if (cb) cb();
    commit("setLoading", false);
  },
  // update publication
  async updatePublication({ commit, state }, { cb, publication }) {
    commit("setLoading", true);
    let publicationList = state.publicationList;
    let updatedPublication = await tapApi.putData(
      "tap/updatePublication",
      publication
    );
    if (updatedPublication.data.status == "SUCCESS") {
      publicationList.forEach((publictions) => {
        if (publictions.id == updatedPublication.data.data[0].id) {
          publictions.publicationType =
            updatedPublication.data.data[0].publicationType;
          publictions.publicationTitle =
            updatedPublication.data.data[0].publicationTitle;
          publictions.publishedName =
            updatedPublication.data.data[0].publishedName;
          publictions.publicationDate =
            updatedPublication.data.data[0].publicationDate;
        }
      });
    }
    commit("setPublicationList", publicationList);
    commit("setPublication", null);
    if (cb) cb();
    commit("setLoading", false);
  },
  // update award
  async updateAward({ commit, state }, { cb, award }) {
    let awardList = Object.assign([], state.awardsList);
    commit("setLoading", true);
    let updatedAward = await tapApi.putData("tap/updateAward", award);
    if (updatedAward.data.status == "SUCCESS") {
      // update the respective award
      awardList.forEach((award) => {
        if (award.id == updatedAward.data.data[0].id) {
          console.log("matched award");
          award.trainerId = updatedAward.data.data[0].trainerId;
          award.awardName = updatedAward.data.data[0].awardName;
          award.awardDescription = updatedAward.data.data[0].awardDescription;
          award.awardYear = updatedAward.data.data[0].awardYear;
        }
      });
      commit("setAwardList", awardList);
      commit("setAward", null);
      if (cb) cb();
    }

    commit("setLoading", false);
  },
  async getACertificaiton({ commit }, certificateId) {
    commit("setLoading", true);
    let getCertification = await tapApi.getData(
      `tap/getACertification?id=${certificateId}`
    );
    commit("setCertification", getCertification.data.data[0]);
    commit("setLoading", false);
  },
  async updateCertification({ commit, state }, { cb, certification }) {
    commit("setLoading", true);
    let updatedCertification = await tapApi.putData(
      "tap/updateCertificate",
      certification
    );
    console.log("Updated Certification ", updatedCertification);
    let certificationList = Object.assign([], state.certificationList);
    if (updatedCertification.data.status == "SUCCESS") {
      certificationList.forEach((certificaion) => {
        if (certificaion.id == updatedCertification.id) {
          certificaion.certificationName =
            updatedCertification.certificationName;
          certificaion.authorityName = updatedCertification.authorityName;
          certificaion.awardedDate = updatedCertification.authorityName;
          certificaion.validTill = updatedCertification.validTill;
        }
      });
      commit("setCertificateList", certificationList);
    }
    if (cb) {
      cb();
    }
    commit("setLoading", false);
  },
  async getAllCertifications({ commit }, trainerId) {
    commit("setLoading", true);
    let getCertifications = await tapApi.getData(
      `tap/getAllCertificates?trainerId=${trainerId}`
    );
    commit("setCertificateList", getCertifications.data.data);
    commit("setLoading", false);
  },
  async getAllAwards({ commit }, trainerId) {
    commit("setLoading", true);
    let getAwards = await tapApi.getData(
      `tap/getAllAwards?trainerId=${trainerId}`
    );
    commit("setAwardList", getAwards.data.data);
    commit("setLoading", false);
    return getAwards.data.data;
  },
  async getAllPublications({ commit }, trainerId) {
    commit("setLoading", true);
    let getAllPublications = await tapApi.getData(
      `tap/getAllPublications?trainerId=${trainerId}`
    );
    commit("setPublicationList", getAllPublications.data.data);
    commit("setLoading", false);
    return getAllPublications.data.data;
  },
  async getAllPatents({ commit }, trainerId) {
    commit("setLoading", true);
    let getAllPatents = await tapApi.getData(
      `tap/getAllPatents?trainerId=${trainerId}`
    );
    console.log("get all patents--", getAllPatents);
    console.log("patent list  ", getAllPatents.data.data);
    commit("setPatentList", getAllPatents.data.data);
    commit("setLoading", false);
    return getAllPatents.data.data;
  },
  async getExtraMiles({ commit }, trainerId) {
    commit("setLoading", true);
    try {
      let getExtraMiles = await tapApi.getData(
        `tap/getExtraMiles?trainerId=${trainerId}`
      );
      let extraMiles = Object.assign({}, getExtraMiles.data.data[0]);
      commit("setLoading", false);
      // modify and set

      let awardList = [];
      for (let i = 0; i < extraMiles.awardList.length; i++) {
        awardList.push(extraMiles.awardList[i].awardName);
      }
      extraMiles.awardList = awardList;
      let certificationList = [];
      for (let j = 0; j < extraMiles.certificationList.length; j++) {
        certificationList.push(
          extraMiles.certificationList[j].certificationName
        );
      }
      extraMiles.certificationList = certificationList;
      let publicationList = [];
      for (let k = 0; k < extraMiles.publicaitonList.length; k++) {
        publicationList.push(extraMiles.publicaitonList[k].publicationTitle);
      }
      extraMiles.publicaitonList = publicationList;
      let patentList = [];
      for (let l = 0; l < extraMiles.patentList.length; l++) {
        patentList.push(extraMiles.patentList[l].patentName);
      }
      extraMiles.patentList = patentList;
      commit("setExtraMiles", getExtraMiles.data.data);
      return extraMiles;
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async createPublication({ commit, state }, { publication, cb }) {
    commit("setLoading", true);
    let publicationList = state.publicationList;
    let createPublicationResponse = await tapApi.postData(
      "tap/addPublicaiton",
      publication
    );
    if (createPublicationResponse.data.status == "SUCCESS") {
      publicationList.unshift(createPublicationResponse.data.data[0]);
      commit("setPublicationList", publicationList);
      commit("setPublication", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async createPatent({ commit, state }, { patent, cb }) {
    commit("setLoading", true);
    let patentList = Object.assign([], state.patentList);
    let createPatentResponse = await tapApi.postData("tap/addPatent", patent);
    if (createPatentResponse.data.status == "SUCCESS") {
      patentList.unshift(createPatentResponse.data.data[0]);
      commit("setPatentList", patentList);
      commit("setPatent", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },

  async createAward({ commit, state }, { award, cb }) {
    commit("setLoading", true);
    let awardList = Object.assign([], state.awardsList);
    let createAwardResponse = await tapApi.postData("tap/addAwards", award);
    if (createAwardResponse.data.status == "SUCCESS") {
      // push to award list
      awardList.unshift(createAwardResponse.data.data[0]);
      commit("setAwardList", awardList);
      commit("setAward", null);
    }
    commit("setLoading", false);
    if (cb) {
      cb();
    }
    // commit("setAward", null);
  },
  async createCertification({ state, commit }, { certification, cb }) {
    commit("setLoading", true);
    let certificaionList = Object.assign([], state.certificationList);
    let createCertificationResponse = await tapApi.postData(
      "tap/addCertification",
      certification
    );
    if (createCertificationResponse.data.status == "SUCCESS") {
      certificaionList.unshift(createCertificationResponse.data.data[0]);
      commit("setCertificateList", certificaionList);
      commit("setCertification", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async updateTestimonial({ commit, state }, { testimonial, cb }) {
    commit("setLoading", true);
    let testimonialList = Object.assign([], state.testimonialList);
    console.log("testimonial to updated------------", testimonial);
    let updatedTestimonial = await tapApi.putData(
      "tap/updateTestimonial",
      testimonial
    );
    if (updatedTestimonial.data.status == "SUCCESS") {
      testimonialList.forEach((testiomonail) => {
        if (testiomonail.id == updatedTestimonial.data.data[0].id) {
          testiomonail.competenceSkill =
            updatedTestimonial.data.data[0].competenceSkill;
          testiomonail.testimonialYear =
            updatedTestimonial.data.data[0].testimonialYear;
          testiomonail.customerPointOFContact =
            updatedTestimonial.data.data[0].customerPointOFContact;
          testiomonail.customerName =
            updatedTestimonial.data.data[0].customerName;
          testiomonail.desigination =
            updatedTestimonial.data.data[0].desigination;
          testiomonail.roleOfPoc = updatedTestimonial.data.data[0].roleOfPoc;
          testiomonail.testimonialText =
            updatedTestimonial.data.data[0].testimonialText;
          testiomonail.sourceOfTestimonial =
            updatedTestimonial.data.data[0].sourceOfTestimonial;
          testiomonail.testimonialAttachementUrl =
            updatedTestimonial.data.data[0].testimonialAttachementUrl;
        }
        commit("setTestimonialList", testimonialList);
        commit("setTestimonial", null);
      });
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async getTestimonialToEdit({ commit }, testimonialId) {
    commit("setLoading", false);
    console.log("testimonial id ---", testimonialId);
    let testimonial = await tapApi.getData(
      `tap/getATestimonial?id=${testimonialId}`
    );
    console.log("Testimonial  ---", testimonial);
    commit("setTestimonial", testimonial.data.data[0]);
    // commit("setEditTestimonial", testimonial.data.data[0]);
    commit("setLoading", false);
  },
  async getAllTestimonials({ commit }, trainerId) {
    commit("setLoading", false);
    let testimonialList = await tapApi.getData(
      `tap/allTestimonials?trainerId=${trainerId}`
    );
    if (testimonialList.data.status == "SUCCESS") {
      // COMMIT TO STATE
      commit("setTestimonialList", testimonialList.data.data);
    }
    commit("setLoading", false);
  },
  async createTesimonial({ commit, state }, { testimonial, cb }) {
    commit("setLoading", false);
    let testimonialList = Object.assign([], state.testimonialList);
    let createTestimonial = await tapApi.postData(
      "tap/addTestimonial",
      testimonial
    );
    if (createTestimonial.data.status == "SUCCESS") {
      testimonialList.unshift(createTestimonial.data.data[0]);
      commit("setTestimonial", null);
      commit("setTestimonialList", testimonialList);
      if (cb) {
        cb();
      }
    }
  },
  async updateRefrence({ commit, state }, { updatedRefrence, cb }) {
    let referenceList = Object.assign([], state.referencesList);
    commit("setLoading", true);
    console.log("updated refrence --", updatedRefrence);
    let updatedRef = await tapApi.putData(
      "tap/updateRefrence",
      updatedRefrence
    );
    if (updatedRef.data.status == "SUCCESS") {
      referenceList.forEach((refrence) => {
        if (refrence.id == updatedRef.data.data[0].id) {
          refrence.topicOfTraining = updatedRef.data.data[0].topicOfTraining;
          refrence.targetAudience = updatedRef.data.data[0].targetAudience;
          refrence.customerName = updatedRef.data.data[0].customerName;
          refrence.customberPocName = updatedRef.data.data[0].customberPocName;
          refrence.designationCustomberPoc =
            updatedRef.data.data[0].designationCustomberPoc;
          refrence.roleOfPoc = updatedRef.data.data[0].roleOfPoc;
          (refrence.contactDetials = {
            email: updatedRef.data.data[0].contactDetials.email,
            contactNumber: updatedRef.data.data[0].contactDetials.contactNumber,
            linkedInProfile:
              updatedRef.data.data[0].contactDetials.linkedInProfile
          }),
            (refrence.referenceAttachmentUrl =
              updatedRef.data.data[0].referenceAttachmentUrl);
        }
      });
      commit("setReferencesList", referenceList);
      commit("setReference", null);
      if (cb) {
        cb();
      }
    }
    commit("setLoading", false);
  },
  async getReferenceToEdit({ commit }, referenceID) {
    commit("setLoading", true);
    console.log("refrence id ", referenceID);
    let refrence = await tapApi.getData(`tap/getAReference?id=${referenceID}`);
    console.log("refnce ---", refrence);
    commit("setReference", refrence.data.data[0]);
    commit("setLoading", false);
  },
  getTrainerProfile({ commit }, trainer) {
    // console.log('get trainer called')
    commit("setTrainerProfileSnackBar",false);
    tapApi
      .getData(`tap/get-trainer-id?trainerId=${trainer.trainerId}`)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          commit("setProfile", res.data.data[0]);
          Store.dispatch("trainerProfile/viewProfilePicture");
          Router.push("/pages/skills-service");
        } else {
          Router.push("/");
          Store.dispatch("project/dealUnsuccessResponse", res.data);
        }
      });
  },
  async getTrainerJorney({ commit }, trainer) {
    // get journey status
    commit("setLoading", true);
    let trainerJourney = await tapApi.getData(
      `tap/trainerJourney?trainerId=${trainer.trainerId}`
    );
    commit("setLoading", false);
    trainerJourney = trainerJourney.data.data[0];
    let keys = Object.keys(trainerJourney);
    for (let key in keys) {
      // console.log('key ---', key)
      if (trainerJourney[keys[key]] == null) {
        trainerJourney[keys[key]] = 1;
      }
      trainerJourney[keys[key]] = trainerJourney[keys[key]] + "";
    }
    //console.log('modified journey', trainerJourney);
    // commit journey
    commit("setEditMode", null);
    commit("setTrainerJourney", trainerJourney);
  },
  async upateTrainerAndTrainerJourney({ commit }, trainerJoruney) {
    console.log("traiener  ------in side store ", trainerJoruney);
    commit("setLoading", true);
    let updateTrainerFlag = false;
    try {
      let trainerProfile = trainerJoruney.profile;
      let trainerProfileResponse = await tapApi.putData(
        "tap/updateTrainer",
        trainerProfile
      );
      console.log("response from trainer profile ", trainerProfileResponse);
      if (trainerProfileResponse.data.status == "SUCCESS") {
        updateTrainerFlag = true;
        commit("setLoading", false);
        commit("setEditMode", null);
        commit("setProfile", trainerProfileResponse.data.data[0]);
      } else {
        commit("setLoading", false);
        Store.dispatch("project/manageAlertBox", {
          status: true,
          text: "Failed To Update Trainer",
          headerText: "Failed",
          afterOk: () => {}
        });
      }
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
    try {
      let trainerJourneyres = await tapApi.putData(
        "tap/saveTrainerJourney",
        trainerJoruney.TrainerJourney
      );
      commit("setLoading", false);
      if (trainerJourneyres.data.status == "SUCCESS") {
        trainerJourneyres = trainerJourneyres.data.data[0];
        let keys = Object.keys(trainerJourneyres);
        for (let key in keys) {
          if (trainerJourneyres[keys[key]] == null) {
            trainerJourneyres[keys[key]] = 1;
          }
          trainerJourneyres[keys[key]] = trainerJourneyres[keys[key]] + "";
        }
        commit("setTrainerJourney", trainerJourneyres);
        commit("setLoading", false);
        Store.dispatch("project/manageAlertBox", {
          status: true,
          text: updateTrainerFlag
            ? `Admin console data updated sucessfully .`
            : `Admin console data updated sucessfully .`,
          headerText: "Sucessful",
          afterOk: () => {}
        });
      } else {
        commit("setLoading", false);
        Store.dispatch("project/manageAlertBox", {
          status: true,
          text: "Failed To Update trainer data .",
          headerText: "Failed",
          afterOk: () => {}
        });
      }
    } catch (e) {
      commit("setLoading", false);
      console.log(e);
    }
  },
  async getTrainerList({ commit }) {
    commit("setLoading", false);
    let trainers = await tapApi.getData("tap/trainers");
    console.log("trainers ---", trainers);
    // filter the data as per requirement
    if (trainers.data.status == "SUCCESS") {
      trainers = trainers.data.data;

      trainers.map((trainer) => {
        let valueOfObject = Object.values(trainer);
        trainer.creationDate = new Date(trainer.creationDate).toLocaleString();
        trainer.fullName = `${trainer.firstName} ${trainer.middleName}  ${trainer.lastName}`;
        // logic to calculate account filled status
        if (valueOfObject.includes("")) {
          trainer.accountFilledStatus = "Partially Filled";
        } else {
          trainer.accountFilledStatus = "Fully Filled";
        }
        trainer.status = "ADMIN CREATED";
        return trainer;
      });
      commit("setTrainerList", trainers);
      commit("setCreateProfile", null);
      commit("setReferencesList", null);
      commit("setLegal", null);
      commit("setOrignalFiles", null);
      commit("setProfile", null);
      commit("setPaymentDetails", null);
      commit("setProfilePictureSrc", null);
      commit("setEditMode", null);
      commit("setGuestFacultyExperince", null);

      // set all the list to null
      commit("setWorkExpList", null);
      commit("setTestimonialList", null);
      commit("setEducation", null);
      commit("setEducationList", null);
      commit("setGuestFacultyExperince", null);
      commit("setTrainerSkillSerivices", null);
      commit("setToolAndPlatfrom", null);
      commit("setAdminComments", null);
      commit("setWorkExpList", null);
      commit("setWorkEx", null);
      commit("setCoursesList", null);
      commit("setGuestFacultyList", null);
    }
    commit("setLoading", false);
  },

  updateTrainerProfile({ commit }, { trainer, cb }) {
    console.log("profile  --- in side vuex", trainer);
    // filter data and the dispatch
    commit("setLoading", false);
    // call the api

    tapApi
      .putData("tap/updateTrainer", trainer)
      .then((res) => {
        console.log("response  ---", res.data);
        if (res.data.status == "SUCCESS") {
          // commit to state
          commit("setLoading", false);
          commit("setProfile", res.data.data[0]);
          // disable edit mode
          commit("setEditMode", null);
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Trainer Updated",
            headerText: "Update Sucessful .",
            afterOk: () => {
              // Router.push("/pages/adminConsole");
            }
          });
        } else {
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: res.data.statusMessage,
            headerText: "Update Failed",
            afterOk: () => {}
          });
          commit("setLoading", false);
        }
      })
      .catch((err) => {
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      })
      .finally(() => {
        commit("setLoading", false);
        if (cb) cb();
      });
  },
  // reset all state
  resetAllState({ commit }) {
    commit("setLoading", false);
    commit("setTrainerProfileSnackBar",false);
    commit("setDelelteAlertDailog", false);
    commit("setAfterOk", null);
    commit("setTrainerSkillServicesDto", null);
    commit("setTrainerSkills", null);
    commit("setCreateProfile", null);
    commit("setReferencesList", null);
    commit("setLegal", null);
    commit("setOrignalFiles", null);
    commit("setProfile", null);
    commit("setPaymentDetails", null);
    commit("setProfilePictureSrc", null);
    commit("setEditMode", null);
    commit("setGuestFacultyExperince", null);
    // set all the list to null
    commit("setWorkExpList", null);
    commit("setTestimonialList", null);
    commit("setEducation", null);
    commit("setEducationList", null);
    commit("setGuestFacultyExperince", null);
    commit("setTrainerSkillSerivices", null);
    commit("setToolAndPlatfrom", null);
    commit("setAdminComments", null);
    commit("setWorkExpList", null);
    commit("setWorkEx", null);
    commit("setCoursesList", null);
    commit("setGuestFacultyList", null);
    commit("setSnackbar",null)
  },
  createTrainer({ commit }, { trainerProfile, cb }) {
    commit("setLoading", true);
    tapApi
      .postData("tap/create-trainer", trainerProfile)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          cb();
          commit("setLoading", false);

          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Trainer sucessfully created",
            headerText: "Sucessful",
            afterOk: () => {}
          });
          commit("setCreateProfile", null);
          // dispatch("getTrainerList");
          Router.push("/pages/searchPage");
        } else {
          Store.dispatch("project/manageAlertBox", {
            status: true,
            text: "Trainer already exist with same email id",
            headerText: "Failed",
            afterOk: () => {}
          });
          commit("setLoading", false);
        }
        commit("setLoading", false);
      })
      .catch((err) => {
        console.log(err);
        commit("setLoading", false);
      });
  },
  async uploadProfilePic({ commit }, { data, trainerId, cb }) {
    if (data) {
      //commit("setLoading", true);
      let formData = new FormData();
      formData.append("file", data);
      formData.append("trainerId", trainerId);
      tapApi
        .uploadData("tap/uploadToS3/profile_images", formData)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            let trainer = Store.state.trainerProfile.profile;
            trainer.profilePic = res.data.data[0];
            console.log("profile pic  updated trainer ", trainer);
          } else {
            Store.dispatch("project/dealUnsuccessResponse", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          Store.dispatch(
            "project/sendErrorNotice",
            "Feature Failed! Will fix soon."
          );
        })
        .finally(() => {
          commit("setLoading", false);
          if (cb) cb();
          return true;
        });
    } else {
      console.log(
        "---------------------------------do nothing just chilll------------------------"
      );
    }
  },
  async updateTrainer({ commit }, { trainer, cb }) {
    commit("setLoading", true);
    let responseFromUpdate = await tapApi.putData(
      "tap/updateTrainerProfilePic",
      trainer
    );

    console.log("set profile picture  ---", responseFromUpdate);

    console.log(
      "profile pic  -----",
      responseFromUpdate.data.data[0].profilePic
    );
    //commit("setProfile", responseFromUpdate.data.data[0]);
    Store.state.trainerProfile.profile.profilePic =
      responseFromUpdate.data.data[0].profilePic;
    commit("setLoading", false);
    Store.dispatch("trainerProfile/viewProfilePicture");
    if (cb) {
      cb();
    }
  },
  viewProfilePicture({ commit }) {
    const url = Store.state.trainerProfile.profile.profilePic;
    if (url === null || url === "") return;
    tapApi
      .downloadData("tap/downloadFromS3?url=" + url)
      .then((res) => {
        const imgSrc = window.URL.createObjectURL(new Blob([res.data]));
        commit("setProfilePictureSrc", imgSrc);
      })
      .catch((err) => {
        console.log("error ocuured in profile pic download");
        console.log(err);
        Store.dispatch(
          "project/sendErrorNotice",
          "Feature Failed! Will fix soon."
        );
      });
  },
  async getAllRefrences({ commit }, trainerId) {
    commit("setLoading", true);
    try {
      let refrenceList = await tapApi.getData(
        `tap/allReferences?trainerId=${trainerId}`
      );
      // console.log('response of all associated referece moddule ----', refrenceList.data.data);
      commit("setReferencesList", refrenceList.data.data);
      commit("setLoading", false);
      return refrenceList.data.data;
    } catch (e) {
      console.log("error ---refrence list ", e);
      commit("setLoading", false);
    }
  },
  createNewReference({ commit, state }, { reference, cb }) {
    commit("setLoading", true);
    let refernceList = Object.assign([], state.referencesList);
    console.log("new refenrence in store", reference);
    tapApi
      .postData("tap/addReference", reference)
      .then((res) => {
        console.log("response of new refrence ", res);
        if (res.data.status === "SUCCESS") {
          console.log("sucess");
          refernceList.unshift(res.data.data[0]);
          commit("setLoading", false);
          commit("setReferencesList", refernceList);
          commit("setReference", null);
          if (cb) {
            cb();
          }
        } else {
          console.log("failed to create refrence");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        commit("setLoading", false);
        if (cb) {
          cb();
        }
      });
  }
};

const mutations = {
  logout(state) {
    state.profile = new TrainerProfile();
    state.loading = false;
  },
  setReference(state, reference) {
    console.log("refernce  -----", reference);
    if (reference) {
      console.log("inside set refrence to");
      state.reference = reference;
    } else {
      state.reference = new References();
    }
  },
  setReferencesList(state, referencesList) {
    if (referencesList) {
      // console.log("inside set refrence list ", referencesList);
      if (referencesList.length != 0) {
        state.referencesList = referencesList;
      }
    } else {
      state.referencesList = new References();
    }
  },
  setTestimonial(state, testimonial) {
    if (testimonial) {
      state.testimonial = testimonial;
    } else {
      state.testimonial = new Testimonial();
    }
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setProfilePictureSrc(state, src) {
    if (src) {
      state.profilePictureSrc = src;
    } else {
      state.profilePictureSrc = "";
    }
  },
  setProfile(state, profile) {
    if (profile === undefined || profile === null) {
      state.profile = new UpdateTrainerProfile();
    } else {
      state.profile = profile;
      state.TrainerJourney.trainerId = profile.trainerId;
    }
  },
  setCreateProfile(state, createProfile) {
    if (createProfile === undefined || createProfile === null) {
      // console.log("here inside set profile case of null")
      state.createProfile = new TrainerProfile();
    } else {
      state.createProfile = createProfile;
    }
  },
  setTrainerList(state, trainerList) {
    if (trainerList.length == 0) {
      state.trainerList = [];
    } else {
      state.trainerList = trainerList;
    }
  },
  setTrainerJourney(state, journey) {
    if (journey) {
      state.TrainerJourney = journey;
    }
  },
  setEditReference(state, refrence) {
    if (refrence) {
      state.editReference = refrence;
    } else {
      state.editReference = new References();
    }
  },
  setCertification(state, certification) {
    if (certification) {
      state.certification = certification;
    } else {
      state.certification = new Certification();
    }
  },
  setAward(state, award) {
    if (award) {
      state.award = award;
    } else {
      state.award = new Awards();
    }
  },
  setPatent(state, patent) {
    if (patent) {
      state.patent = patent;
    } else {
      state.patent = new Patents();
    }
  },
  setPublication(state, publication) {
    if (publication) {
      state.publication = publication;
    } else {
      state.publication = new Publications();
    }
  },
  setExtraMiles(state, extraMiles) {
    if (extraMiles) {
      state.extraMilesList = extraMiles;
    } else {
      state.extraMilesList = [];
    }
  },
  setCertificateList(state, certifcateList) {
    if (certifcateList) {
      state.certificationList = certifcateList;
    } else {
      state.certificationList = [];
    }
  },
  setAwardList(state, awardList) {
    if (awardList) {
      state.awardsList = awardList;
    } else {
      state.awardList = [];
    }
  },
  setPublicationList(state, publicationList) {
    if (publicationList) {
      state.publicationList = publicationList;
    } else {
      state.publicationList = [];
    }
  },
  setPatentList(state, patentList) {
    if (patentList) {
      state.patentList = patentList;
    } else {
      state.patentList = [];
    }
  },
  setEditPublicaiton(state, publication) {
    if (publication) {
      state.editPublication = publication;
    } else {
      state.editPublication = new Publications();
    }
  },
  setTopCourse(state, topCourse) {
    if (topCourse) {
      state.topCourse = topCourse;
    } else {
      state.topCourse = new TopCourses();
    }
  },
  setCoursesList(state, courseList) {
    if (courseList) {
      state.coursesList = courseList;
    } else {
      state.coursesList = [];
    }
  },
  setTrainerFiles(state, files) {
    if (files) {
      state.trainerFiles = files;
    } else {
      state.trainerFiles = new TrainerFiles();
    }
  },
  setLegal(state, legal) {
    if (legal) {
      state.legal = legal;
    } else {
      console.log("no legal found");
      console.log("new legal ---", new Legal());
      state.legal = new Legal();
    }
  },
  setLegalDocumentSource(state, legalSourse) {
    if (legalSourse) {
      state.legalDocumentSource = legalSourse;
    } else {
      state.legalDocumentSource = "";
    }
  },
  setPaymentDetails(state, paymentDetials) {
    if (paymentDetials) {
      state.paymentDetials = paymentDetials;
    } else {
      state.paymentDetials = new PaytmentDetialsDto();
    }
  },
  setOrignalFiles(state, originalFiles) {
    if (originalFiles) {
      state.trainerOriginalFiles = originalFiles;
    } else {
      state.trainerOriginalFiles = new TrainerOriginalFiles();
    }
  },
  setWorkEx(state, workEx) {
    if (workEx) {
      state.workEx = workEx;
    } else {
      state.workEx = new WorkExperince();
    }
  },
  setWorkExpList(state, workExpList) {
    if (workExpList) {
      state.workExpList = workExpList;
    } else {
      state.workExpList = [];
    }
  },
  setGuestFacultyList(state, guestFacultyList) {
    if (guestFacultyList) {
      state.guestFauculyList = guestFacultyList;
    } else {
      state.guestFauculyList = [];
    }
  },
  setAdminComments(state, adminComments) {
    console.log("inside set addmin comments set state");
    if (adminComments) {
      state.adminComments = adminComments;
    } else {
      state.adminComments = new AdminCommnets();
    }
  },
  setEditMode(state, editMode) {
    console.log("edmit mode setter", editMode);
    if (editMode) {
      console.log("edmit mode setter conddition true");
      state.editMode = editMode;
    } else {
      state.editMode = {
        trainerProfile: false,
        adminConsole: false,
        orginalFiles: false,
        paymentDetails: false,
        legal: false
      };
    }
  },
  setTrainerSkillServicesDto(state, trainerSkillServicesDto) {
    if (trainerSkillServicesDto) {
      state.trainerSkillServicesDto = trainerSkillServicesDto;
    } else {
      state.trainerSkillServicesDto = new SkillServicesDto();
    }
  },
  setTrainerSkills(state, trainerSkills) {
    if (trainerSkills) {
      state.trainerSkills = trainerSkills;
    } else {
      state.trainerSkills = new TrainerSkills();
    }
  },
  setToolAndPlatfrom(state, toolsAndPlaform) {
    if (toolsAndPlaform) {
      state.trainerToolsAndPlatfrom = toolsAndPlaform;
    } else {
      state.trainerToolsAndPlatfrom = new TrainerToolsAndPlatfrom();
    }
  },
  setTrainerSkillSerivices(state, skillAndService) {
    if (skillAndService) {
      state.trainerSkillSerivices = skillAndService;
    } else {
      state.trainerSkillSerivices = new TrainerSkillServicesDelivery();
    }
  },
  setGuestFacultyExperince(state, guestFaculty) {
    if (guestFaculty) {
      state.guestFacultyExp = guestFaculty;
    } else {
      state.guestFacultyExp = new TrainerGuestFaculty();
    }
  },
  setEducationList(state, educationList) {
    if (educationList) {
      state.educationList = educationList;
    } else {
      state.educationList = [];
    }
  },
  setEducation(state, education) {
    if (education) {
      state.education = education;
    } else {
      state.education = new EducationDto();
    }
  },
  setTestimonialList(state, testimonialList) {
    if (testimonialList) {
      state.testimonialList = testimonialList;
    } else {
      state.testimonialList = [];
    }
  },
  // set admin comment list
  setAdminCommentsList(state, adminCommentsList) {
    if (adminCommentsList) {
      state.adminCommentsList = adminCommentsList;
    } else {
      state.adminCommentsList = [];
    }
  },
  setDelelteAlertDailog(state, value) {
    state.deleteAlertBoxSatus = value;
  },
  setAfterOk(state, afterOk) {
    if (afterOk) {
      state.afterOk = afterOk;
    } else {
      state.afterOk = "";
    }
  },
  setLinkedInScrapperDto(state, scrapperDto) {
    if (scrapperDto) {
      state.linkedInScrapperDto = scrapperDto
    } else {
      state.linkedInScrapperDto =new LinkedinScrapperDto()
    }
  },
  setSnackbar(state, value) {
    if (value) {
      state.snackbar_linkedin=true
    } else {
      state.snackbar_linkedin=false
    }
  },
  setAlertBoxScapper(state, value) {
    if (value) {
      state.alertBoxScapper=value
    } else {
      console.log('---------inside the alert scrapper close -------');
      state.alertBoxScapper={
        headerText: "",
        status: false,
        message: "",
        afterDeleteConfirmation: "",
      }
    }
  },
  setTrainerProfileSnackBar(state, value) {
    if (value) {
      state.snackBarTrainerProfile=true
    } else {
      state.snackBarTrainerProfile=false
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
