export class TopCourses {
  constructor() {
    this.trainerId = null;
    this.courseName = "";
    this.courseComplexity = "";
    this.courseDuration="";
    this.targetAudienceDesignation = "";
    this.tragetIndustry = "";
    this.targetDepartment = "";
    this.minimumFees = 0.0;
    this.standardFees = 0.0;
    this.associatedCertification = null; // HashMap<Integer,String>
    this.associatedAwards = null;
    this.associatedPatent = null;
    this.associatedPublications = null;
    this.trainingsDeliveredForCourse = "";
    this.sessionDeliveredForCourse = "";
    this.keySkillOfCourse = [];
    this.keyCleintsCourse = [];
    this.courseSKillsconsultingExperince = false;
    this.appliedSkillInCorporate = false;
    this.courseOutlineUrl = "";
  }
}
