<template>
 <div 
    class="lmask" 
    v-if="visible"
 >
 <div 
    class="loader" 
    :class="{ 'loader--visible': visible }"
 >
   <PulseLoader color="#283593"/>
  </div>
 
 </div>
   <!-- <PulseLoader color="indigo"/>
  </div> -->
</template>

<script>
import { PulseLoader } from '@saeris/vue-spinners'
export default {
    name:"loader",
     props: ["visible"],
     components:{
       PulseLoader
     }
}
</script>
<style lang="scss">
.lmask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.4;
  &.fixed {
    position: fixed;
  }
 
};
.loader {
  transition: 0.15s ease-in opacity;
  position: absolute;
  top: 40%;
  left: 48%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  &--visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>