/* globals Router, Store */
import axios from 'axios'
// import Endpoints from '../endpoints';

// local url
// const BASE_URL = 'http://192.168.0.111:8080/tapRest/'

const BASE_URL = process.env.VUE_APP_BASE_URL_TAP


const instance = axios.create({
  baseURL: "",
  timeout: false,
  params: {} // do not remove this, its added to add params later in the config
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  const  token = Store.state.userAuthenticaiton.token
  console.log("token  in tap api--", token)
  if (!token) {
    console.log('code came win tonen not there');
    Router.push('/pages/authentication/login')
  }
  if (token) {
   config.headers.common['Authorization'] = 'Bearer ' + token
     config.headers.common['Access-Control-Allow-Origin'] = '*'
     config.headers.common['user-auth-token'] = token
 } else {
   // Router.push('/pages/authentication/login')
    // Use application/x-www-form-urlencoded for login
   config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
 }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response === undefined || error.response.status === undefined) {
    console.log('server not working ...')
    // Store.dispatch('project/manageAlertBox',
    //   {
    //     status: true,
    //     text: 'Server Got Fatal Error, Will Fix soon! Please login again.'
    //   })
  } else if (error.response.status === 401 && Router.currentRoute.name !== 'Login') {
    console.log('unauthorized, logging out ...')
  const  token = Store.state.userAuthenticaiton.token

    if (token) {
      Store.dispatch('project/manageAlertBox',
      {
        status: true,
        text: 'Session Expired! Please login again.',
        'headerText': "Failed",
        'afterOk': () => {
          Router.push(`/pages/authentication/login`)
        }
      })
    }       
    
  }
  return Promise.reject(error)
})

export default {
  getData (action) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url)
  },
  postData (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data)
  },
  putData (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.put(url, data)
  },
  deleteData (action) {
    let url = `${BASE_URL}`
    url += action
    return instance.delete(url)
  },
  uploadData (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  downloadData (action) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url, {
      responseType: 'blob'
    })
  },
  login (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url, {
      headers: { 'Authorization': data }
    })
  }
}
