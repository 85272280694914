export class TrainerGuestFaculty {
  constructor() {
    this.trainerId = null;
    this.description = "";
    this.numberOfSessionDelivered = null;
    this.startDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);

    this.endDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10);

    this.keySkills = [];
    this.instituteName = "";
    this.designationName = "";
  }
}
