// /* globals Store */
/*eslint-disable */
import tapApi from "@/utils/api/tap-api";

import { SkillLeaf } from "../../models/skillLeaf";

const state = {
  skill: new SkillLeaf(),
  l1Skills: [],
  l2Skills: [],
  l3Skills: [],
  l4Skills: [],
  selectedL1Skill: null,
  selectedL2Skill: null,
  selectedL3Skill: null,
  notice:'',
  mode: '', 
  snackbar: false, 
  snackBar: '',
  loading: false,
  deleteAlertBoxSatus:false,
  afterOk:''
};
const getters = {};

const actions = {
  closeDeleteDailog({commit}) {
    console.log("close dailog calledd");
    commit('setDelelteAlertDailog',false)
  },
  getL1Skills({ commit }) {
    commit("setLoading", false);
    tapApi.getData("/skill-db/getAllL1Skills").then((res) => {
      // console.log("response of all l1 skill ", res.data.data);
      if (res.data.status == "SUCCESS") {
        console.log(res.data.data);
        commit("setL1Skills", res.data.data);
        commit("setL2Skills", null);
        commit("setL3Skills",null);
        commit("setL4Skills", null);
        commit("setSelectedL1Skill", null)
        commit("setSelectedL2Skill", null)
        commit("setSelectedL3Skill", null)
        commit('setDelelteAlertDailog',false)
      } else {
        console.log("failed to fetch");
      }
    });
  },
  createSkill({ commit ,state,dispatch}, { skill, cb }) {
    commit("setLoading", false);
    if (skill) {
      tapApi.postData("/skill-db/create-skill", skill).then((res) => {
        if (res.data.status == "SUCCESS") {
           commit("setSkill", null);
          dispatch("project/manageAlertBox", {
            status: true,
            text: res.data.statusMessage,
            headerText: "Sucessful",
            afterOk: () => {}
          },{root:true});
          // based on skill level add to the respective array and call respective message in alert box
          if (res.data.data[0].skillLevel == 'L1') {
            let l1Skills = Object.assign([], state.l1Skills);
            l1Skills.unshift(res.data.data[0])
            commit("setL1Skills", l1Skills);
          } else if (res.data.data[0].skillLevel == 'L2') {
            let l2Skills = Object.assign([], state.l2Skills);
            l2Skills.unshift(res.data.data[0])
            commit("setL2Skills", l2Skills);
          }else if (res.data.data[0].skillLevel == 'L3') {
            let l3Skills = Object.assign([], state.l3Skills);
            l3Skills.unshift(res.data.data[0])
            commit("setL3Skills", l3Skills);
          }else if (res.data.data[0].skillLevel == 'L4') {
            let l4Skills = Object.assign([], state.l4Skills);
            l4Skills.unshift(res.data.data[0])
            commit("setL4Skills", l4Skills);
          }
          if (cb) {
            cb();
          }
        } else if(res.data.status == "ERROR"){
          // send errror notice
          dispatch('sendErrorNotice',res.data.statusMessage)
        }
      });
    } else {
      commit("setSkill", null);
      if (cb) {
        cb();
      }
    }
  },
  deleteSkill({ commit,state,dispatch}, { skillId,deleteConfirmation ,skillLevel}) {
    commit("setLoading", false);
    if (deleteConfirmation) {
      commit('setDelelteAlertDailog',false)
      tapApi.deleteData("/skill-db/delete-skill/" + skillId).then((res) => {
        if (res.data.status == "SUCCESS") {
          dispatch("project/manageAlertBox", {
            status: true,
            text: res.data.statusMessage,
            headerText: "Sucessful",
            afterOk: () => {}
          },{root:true});
          // delte from respective l1, l2,l3
          if (skillLevel == 'L1') {
            let l1Skills = Object.assign([], state.l1Skills);
            l1Skills =l1Skills.filter(skill =>skill.skillId !=skillId)
            commit("setL1Skills", l1Skills);
          } else if (skillLevel == 'L2') {
            let l2Skills = Object.assign([], state.l2Skills);
            l2Skills =l2Skills.filter(skill =>skill.skillId !=skillId)
            commit("setL2Skills", l2Skills);
          } else if (skillLevel == 'L3') {
            let l3Skills = Object.assign([], state.l3Skills);
            l3Skills =l3Skills.filter(skill =>skill.skillId !=skillId)
            commit("setL3Skills", l3Skills);
          } else if (skillLevel == 'L4') {
            let l4Skills = Object.assign([], state.l4Skills);
            l4Skills =l4Skills.filter(skill =>skill.skillId !=skillId)
            commit("setL4Skills", l4Skills);
          }
        } else {
          dispatch("project/manageAlertBox", {
            status: true,
            text: res.data.statusMessage,
            headerText: "Failed",
            afterOk: () => {}
          },{root:true});
        }
      });
    } else {
      commit('setDelelteAlertDailog', true)
      commit('setAfterOk', {
        skillId: skillId,
        skillLevel:skillLevel
      })
    }
    
  },
  getSkillUsingId({ commit }, { skillId }) {
    console.log("skill id inside state", skillId);
    commit("setLoading", false);
    tapApi.getData(`/skill-db/getSkill?skillId=${skillId}`).then((res) => {
      console.log("get a skill  --", res.data.data[0]);
      commit("setSkill", res.data.data[0]);
    });
  },
  updateSkill({ commit,dispatch ,state }, { skill, cb }) {
    commit("setLoading", false);
    tapApi.putData("/skill-db/updateSkill", skill).then((res) => {
      console.log("get a skill  --", res.data.data[0]);
      if (res.data.status == "SUCCESS") {
        // interate and update that paritucular skill
        dispatch("project/manageAlertBox", {
          status: true,
          text: res.data.statusMessage,
          headerText: "Sucessful",
          afterOk: () => {}
        }, { root: true });
        if (res.data.data[0].skillLevel == 'L1') {
          // find in l1 skills and updated 
          let l1Skills = Object.assign([], state.l1Skills);
          l1Skills.forEach(l1skill => {
            if (l1skill.skillId == res.data.data[0].skillId) {

              l1skill.skillId = res.data.data[0].skillId
              l1skill.skillName =res.data.data[0].skillName,
              l1skill.skillSynonyms =res.data.data[0].skillSynonyms,
              l1skill.skillLevel = res.data.data[0].skillLevel;
              l1skill.parentSkillId = res.data.data[0].parentSkillId;
            }
          })
            console.log('update skill list -----' ,l1Skills);
          // l1Skills.unshift(res.data.data[0])
          commit("setL1Skills", l1Skills);
        } else if (res.data.data[0].skillLevel == 'L2') {
          let l2Skills = Object.assign([], state.l2Skills);
          l2Skills.forEach(l2skill => {
            if (l2skill.skillId == res.data.data[0].skillId) {

              l2skill.skillId = res.data.data[0].skillId
              l2skill.skillName =res.data.data[0].skillName,
              l2skill.skillSynonyms =res.data.data[0].skillSynonyms,
              l2skill.skillLevel = res.data.data[0].skillLevel;
              l2skill.parentSkillId = res.data.data[0].parentSkillId;
            }
          })
            console.log('update skill list -----' ,l2Skills);
          // l1Skills.unshift(res.data.data[0])
          commit("setL2Skills", l2Skills);
        } else if (res.data.data[0].skillLevel == 'L3') {
          let l3Skills = Object.assign([], state.l3Skills);
          l3Skills.forEach(l3skill => {
            if (l3skill.skillId == res.data.data[0].skillId) {

              l3skill.skillId = res.data.data[0].skillId
              l3skill.skillName =res.data.data[0].skillName,
              l3skill.skillSynonyms =res.data.data[0].skillSynonyms,
              l3skill.skillLevel = res.data.data[0].skillLevel;
              l3skill.parentSkillId = res.data.data[0].parentSkillId;
            }
          })
            console.log('update skill list -----' ,l3Skills);
          // l1Skills.unshift(res.data.data[0])
          commit("setL3Skills", l3Skills);
        } else if (res.data.data[0].skillLevel == 'L4') {
          let l4Skills = Object.assign([], state.l4Skills);
          l4Skills.forEach(l4skill => {
            if (l4skill.skillId == res.data.data[0].skillId) {

              l4skill.skillId = res.data.data[0].skillId
              l4skill.skillName =res.data.data[0].skillName,
              l4skill.skillSynonyms =res.data.data[0].skillSynonyms,
              l4skill.skillLevel = res.data.data[0].skillLevel;
              l4skill.parentSkillId = res.data.data[0].parentSkillId;
            }
          })
            console.log('update skill list -----' ,l4Skills);
          // l1Skills.unshift(res.data.data[0])
          commit("setL4Skills", l4Skills);
        }
        if (cb) {
          cb();
        }
      } else if(res.data.status == "ERROR"){
        dispatch('sendErrorNotice',res.data.statusMessage)
      }
    });
  },
  getChildrenSkills({ commit }, { skillId, skillLevel }) {
    //console.log("skill level ---state", skillLevel);
    commit("setLoading", false);
    tapApi
      .getData(`/skill-db/getChildrenSkills?parentId=${skillId}`)
      .then((res) => {
        console.log("chidren skills", res);
        if (skillLevel == "L1") {
          commit("setL2Skills", res.data.data);
          commit("setL3Skills",null);
          commit("setL4Skills", null);
          commit("setSelectedL2Skill", null)
          commit("setSelectedL3Skill",null)
        }
        if (skillLevel == "L2") {
          commit("setL3Skills", res.data.data);
          commit("setL4Skills", null)
          commit("setSelectedL3Skill",null)
        }
        if (skillLevel == "L3") {
          commit("setL4Skills", res.data.data);
        }
      });
  },
  selectedSkill({ commit ,state }, skillEntity) {
    commit("setLoading", false);
    // console.log('skill entity  ---', skillEntity)
    // console.log('skill entity  ---state', skillEntity.skillLevel)
    if (skillEntity.skillLevel == 'L1') {
      commit("setSelectedL1Skill", skillEntity)
      let l1Skills = Object.assign([],state.l1Skills);

  //   console.log('l1 skills deep copied',l1Skills)
     l1Skills.forEach(l1skiil => {
        if (l1skiil.skillId == skillEntity.skillId) {
          l1skiil.isSelected = true;
         // l1skiil.skillName="No updating"
        } else {
          l1skiil.isSelected = false;
          // l1skiil.skillName="No updating"
          }
      })
      
      console.log("l1 skills--- " ,l1Skills);
      commit('setL1Skills',l1Skills)
    } else if (skillEntity.skillLevel == 'L2') {

      let l2Skills = Object.assign([],state.l2Skills);

  //   console.log('l1 skills deep copied',l1Skills)
     l2Skills.forEach(l2skiil => {
        if (l2skiil.skillId == skillEntity.skillId) {
          l2skiil.isSelected = true;
         // l1skiil.skillName="No updating"
        } else {
          l2skiil.isSelected = false;
          // l1skiil.skillName="No updating"
          }
     })
     commit('setL2Skills',l2Skills)
     // console.log('l2 level skill selected');
      commit('setSelectedL2Skill',skillEntity)
    } else if (skillEntity.skillLevel == 'L3') {
      let l3Skills = Object.assign([],state.l3Skills);

      //   console.log('l1 skills deep copied',l1Skills)
         l3Skills.forEach(l3skiil => {
            if (l3skiil.skillId == skillEntity.skillId) {
              l3skiil.isSelected = true;
             // l1skiil.skillName="No updating"
            } else {
              l3skiil.isSelected = false;
              // l1skiil.skillName="No updating"
              }
         })
         commit('setL3Skills',l3Skills)
      commit('setSelectedL3Skill',skillEntity)
    }
    
  },
  sendSuccessNotice ({ commit, dispatch }, notice) {
    commit('setNotice', {
      notice
    })
    commit('setSnackbar', {
      snackbar: true
    })
    commit('setMode', {
      mode: 'primary'
    })
    dispatch('closeNotice', 1500)
  },
  sendErrorNotice({ commit, dispatch }, notice) {
    console.log('notice ' ,notice);
    commit('setNotice', {
      notice
    })
    commit('setSnackbar', {
      snackbar: true
    })
    commit('setMode', {
      mode: 'error'
    })
    dispatch('closeNotice', 20000)
  },
  sendWarningNotice ({ commit, dispatch }, notice) {
    commit('setNotice', {
      notice
    })
    commit('setSnackbar', {
      snackbar: true
    })
    commit('setMode', {
      mode: 'secondary'
    })
    dispatch('closeNotice', 10000)
  },
  dealUnsuccessResponse ({ commit, dispatch }, response) {
    if (response.status === 'WARNING') {
      dispatch('sendWarningNotice', response.statusMessage)
    } else {
      dispatch('sendErrorNotice', 'Seems there is a problem, please try after some time.')
    }
  },
  closeNotice({ commit }, timeout) {
    console.log('inside close notice ' , timeout);
    setTimeout(() => {
      commit('setSnackbar', {
        snackbar: false
      })
      commit('setNotice', {
        notice: ''
      })
      commit('setMode', {
        mode: ''
      })
    }, timeout)
  }
};
const mutations = {
  setDelelteAlertDailog(state, value) {
    state.deleteAlertBoxSatus=value
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setSelectedL1Skill(state, skillEntity) {
    if (skillEntity) {
      console.log('mutations selected skill l1 ' , skillEntity);
      state.selectedL1Skill=skillEntity
    } else {
      state.selectedL1Skill=null
    }
  },
  setSelectedL2Skill(state, skillEntity) {
    if (skillEntity) {
      console.log('mutations selected skill l2 ' , skillEntity);
      state.selectedL2Skill=skillEntity
    } else {
      state.selectedL2Skill=null
    }
  },
  sendWarningNotice ({ commit, dispatch }, notice) {
    commit('setNotice', {
      notice
    })
    commit('setSnackbar', {
      snackbar: true
    })
    commit('setMode', {
      mode: 'secondary'
    })
    dispatch('closeNotice', 10000)
  },
  setSelectedL3Skill(state, skillEntity) {
    if (skillEntity) {
      console.log('mutations selected skill l3 ' , skillEntity);
      state.selectedL3Skill=skillEntity
    } else {
      state.selectedL3Skill=null
    }
  },
  setL2Skills(state, skill) {
    if (skill) {
      state.l2Skills = skill;
    } else {
      state.l2Skills = [];
    }
  },
  setL3Skills(state, skill) {
    if (skill) {
      state.l3Skills = skill;
    } else {
      state.l3Skills = [];
    }
  },
  setL4Skills(state, skill) {
    if (skill) {
      state.l4Skills = skill;
    } else {
      state.l4Skills = [];
    }
  },
  setSkill(state, skill) {
    if (skill) {
      state.skill = skill;
    } else {
      state.skill = new SkillLeaf();
    }
  },
  setL1Skills(state, l1skills) {
    console.log("state s-----", state);
    console.log("inside mutaions ", l1skills);
    if (l1skills.length == 0) {
      state.l1Skills = [];
    } else {
      state.l1Skills = l1skills;
    }
  },
  manageAlertBox (state, { status, text, afterOk }) {
    state.alertBoxText = text
    state.alertBoxStatus = status
    state.afterOk = afterOk
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setNotice (state, { notice }) {
    state.notice = notice
  },
  setSnackbar (state, { snackbar }) {
    state.snackbar = snackbar
  },
  setMode (state, { mode }) {
    state.mode = mode
  },
  setAfterOk(state, afterOk) {
    state.afterOk=afterOk
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};
