export  class References {
  constructor() {
    this.trainerId=null,
    this.topicOfTraining = "";
    this.targetAudience = "";
    this.customerName = "";
    this.customberPocName = "";
    this.designationCustomberPoc = "";
    this.roleOfPoc = "";
    this.contactDetials = {
      email: '',
      contactNumber: '',
      linkedInProfile:''
    },
    this.referenceAttachmentUrl=''
  }
}
