export default {
  show: true,
  clippedOver: true,
  dense: true,
  shrinkOnScroll: false,
  hideOnScroll: true,
  prominent: false,
  floating: true,
};

// clippedOver: true
// dense: true
// floating: true
// hideOnScroll: true
// prominent: false
// show: true
// shrinkOnScroll: false