import { TrainerCopmanyDetials } from './TrainerCompanyDetails'
import { TrainerBankDetials } from './TrainerBankDetails'
import {TrainerFees } from './TrainerFees'
import { TrainerGst } from './TrainerGst'
import { TrainerPanDetails } from './TrainerPanDetials'

export class PaytmentDetialsDto{

    constructor() {
        this.trainerFees = {
            TWO_HOUR_FEES: new TrainerFees('TWO_HOUR_FEES'),
            HALF_DAY_FEES: new TrainerFees('HALF_DAY_FEES'),
            FULL_DAY_FEES:new TrainerFees('FULL_DAY_FEES')   
        };
        this.trainerBankDetials = new TrainerBankDetials();
        this.trainerCompanyDetails = new TrainerCopmanyDetials();
        this.trainerPanDetials = new TrainerPanDetails();
        this.trainerGst = new TrainerGst();
    }
    // trainerFees:, TrainerBankDetials trainerBankDetials,
	// 		TrainerCompany trainerCompanyDetails, TrainerPan trainerPanDetials, TrainerGst trainerGst

}