export default {
  app: true,
  show: true,
  inset: false,
  padless: true,
  fixed: true,
  absolute: false,
};
// absolute: false
// app: true
// fixed: true
// inset: false
// padless: true
// show: true