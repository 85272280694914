export class TrainerProfile {
  constructor() {
    this.id = null;
    // long auto genrated in backend
    this.firstName = ""; // string
    this.middleName = "";
    this.lastName = "";
    this.primaryEmailId = null;
    this.secondaryEmail = "";
    this.primaryPhoneNumber = "";
    this.secondaryPhoneNumber = "";
    this.profilePic = null;
    this.currentAddressDoorNumber = "";
    this.currentAddressStreet = "";
    this.currentAddressState = "";
    this.currentAddressCity = "";
    this.currentAddressPin = "";
    this.currentAddressCountry = "";
    this.baseLocation = "";
    this.trainerCode = "";
    this.publicProfileUrl = "";
    this.linkedinProfileUrl = "";
    this.gitProfileUrl = "";
    this.stackoverflowProfileUrl = "";
    this.introVideoUrl = "";
    this.resumePrivateUrl = "";
    this.sampleTrainingVideoUrl = "";
    this.domainIndustry = "";
    this.summary = "";
    this.totalTrainingExperience = 0; // number integer
    this.trainerPersona = null;
    this.createdBy = "";
    this.otOwner = "";
    this.trainerProcueredFrom = "";
    this.trainerOtHistory = false; // boolean
    this.trainerOraginzation = "";
    this.sourceType = "";
    this.professionalOverview = "";
    this.professionalLinks = null; // hashmap
    this.personalLinks = null; // hashmap
    this.startedFreelancingYear = 0;
    this.dateAvailable = "";
    this.additionalConstraints = "";
    this.suggestedBy = "";
    this.linkToAsana = "";
    this.numberOfOq = 0;
    this.rawCoursesTrainer = "";
    this.validationDataForSkills = "";
  }
}
