export class TrainerSkillServicesDelivery {
  constructor() {
    this.trainerId = null;
    this.totalWorkExYears = "";
    this.totalWorkExMonths = "";
    this.totalTrainingExYears = "";
    this.totalTrainingExMonths = "";
    this.trainingSessionDelivered = "";
    this.timeDedicatedConsulting = "";
    this.averageTrainingDeliveredMonthly = "";
    this.averageTrainingDeliveredMonthlyCollege = "";
    this.totalClientsTrainingDelivered = "";
    this.businnessFromEndCustomber = "";
    this.keyClients = [];
    this.keyClientsVisibility = false;
    this.repeatClients = [];
    this.globalClients = [];
    this.globalClientVisibility = false;
  }
}
