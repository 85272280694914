<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card elevation="1">
      <v-card-title class="justify-center">
        <div class=" text-h5  font-weight-bold">
        {{sucessOrFailure}}
        </div>
       </v-card-title>
       <v-divider></v-divider>
      <v-card-text >
        <div class=" text-h6 font-weight-bold ">
        {{dialogText}}
        </div>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn  color="indigo" class="bold--text white--text" style="background-color:indigo"  @click.native="$emit('onOk')" >Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name:"AlertDailog",
  props: ["dialogText","dialog","sucessOrFailure"],
  created() {
    console.log('header for sucess and failure ',this.sucessOrFailure);
  },
}
</script>
