<template>
  <svg
    viewBox="0 0 2211 2354"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-2" transform="translate(-395.244997, -399.296135)">
        <g id="Arks" :fill="primary">
          <path
            d="M1656.245,2597.86739 L1656.43959,2597.53034 C1657.62344,2597.36311 1658.80668,2597.194 1659.98931,2597.02303 C2098.05955,2492.66218 2494.48989,1972.7232 2494.48989,1502.94951 C2494.48989,1403.85453 2479.99635,1308.1447 2453.01345,1217.82418 L2526.06431,1091.29614 C2577.2951,1218.65615 2605.48989,1357.76021 2605.48989,1503.44951 C2605.48989,2059.55907 2194.68629,2519.72032 1659.98931,2597.02303 C1659.53052,2597.13232 1659.07169,2597.24116 1658.61281,2597.34955 C1657.81567,2597.53783 1657.02646,2597.71033 1656.245,2597.86739 Z"
            id="Combined-Shape"
          ></path>
          <path
            d="M416,2636.37843 L416.194597,2636.04138 C417.378441,2635.87415 418.561683,2635.70504 419.744318,2635.53407 C857.814551,2531.17322 1254.24489,2011.23424 1254.24489,1541.46055 C1254.24489,1442.36557 1239.75135,1346.65573 1212.76846,1256.33522 L1285.81932,1129.80717 C1337.0501,1257.16719 1365.24489,1396.27125 1365.24489,1541.96055 C1365.24489,2098.07011 954.441294,2558.23136 419.744318,2635.53407 C419.285524,2635.64336 418.826691,2635.7522 418.367812,2635.86059 C417.570674,2636.04887 416.781463,2636.22137 416,2636.37843 Z"
            id="Combined-Shape-Copy"
            transform="translate(890.622444, 1883.092800) rotate(120.000000) translate(-890.622444, -1883.092800) "
          ></path>
          <path
            d="M1004.41273,1541.57125 L1004.60733,1541.2342 C1005.79117,1541.06697 1006.97441,1540.89787 1008.15705,1540.72689 C1446.22728,1436.36605 1842.65762,916.427067 1842.65762,446.653374 C1842.65762,347.558394 1828.16409,251.84856 1801.18119,161.528044 L1874.23205,35 C1925.46283,162.360019 1953.65762,301.464074 1953.65762,447.153374 C1953.65762,1003.26294 1542.85403,1463.42418 1008.15705,1540.72689 C1007.69826,1540.83619 1007.23942,1540.94503 1006.78054,1541.05341 C1005.98341,1541.24169 1005.1942,1541.4142 1004.41273,1541.57125 Z"
            id="Combined-Shape-Copy-2"
            transform="translate(1479.035176, 788.285626) rotate(240.000000) translate(-1479.035176, -788.285626) "
          ></path>
        </g>
        <g id="Group" transform="translate(420.563066, 879.831160)">
          <polygon
            id="Path"
            :fill="baseColor"
            points="0 0 579.958773 1007.05697 1018.2638 251.060781 892.335699 34.1178053 579.958773 574.096767 393.437134 251.060781 536.891879 0"
          ></polygon>
          <polygon
            id="Triangle"
            stroke="#979797"
            :fill="baseColor"
            transform="translate(1079.681930, 71.464975) scale(1, -1) translate(-1079.681930, -71.464975) "
            points="1079.68193 1.46497533 1159.68193 141.464975 999.68193 141.464975"
          ></polygon>
          <polygon
            id="Path-2"
            :fill="primary"
            points="1080.17552 573.815042 952.801082 790.650165 1080.17552 1007.05697 1205.02879 790.650165"
          ></polygon>
          <polyline
            id="Path-3"
            stroke="#B31B1B"
            points="643.936517 1117.43649 1080.42844 1873.02896 2161.10782 0.545693555 1286.75554 0.464975328"
          ></polyline>
          <polygon
            id="Path-4"
            :fill="baseColor"
            points="1266.22195 466.598613 1142.68202 251.464975 1286.75554 0.464975328 2161.10782 0.545693555 1080.42844 1873.02896 641.390053 1114.85172 767.229977 897.611042 1080.42844 1438.99824 1784.2231 217.220151 1411.0133 217.220151"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
  <!-- <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="525.000000pt"
    height="417.000000pt"
    viewBox="0 0 525.000000 417.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,417.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    ></g>
  </svg> -->
</template>
<script>
import colors from "vuetify/es5/util/colors";
export default {
  name: "Logo",
  props: {
    size: {
      type: [String, Number],
      default: "50",
    },
    semiDark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseColor() {
      return this.$vuetify.theme.dark
        ? colors.grey.lighten5
        : this.semiDark
        ? colors.grey.lighten5
        : colors.grey.darken4;
    },
    primary() {
      return this.$vuetify.theme.themes.light.primary.base;
    },
  },
};
</script>
