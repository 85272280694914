export class User {
  constructor () {
    // this.id = 0 // length=20
    this.firstName = '' // length = 50
    this.middleName = '' // length = 50
    this.lastName = '' // length = 50
    this.gender = '' // length = 10
    this.dob = '' // length = 15
    this.email = '' // length = 128
    this.countryCode = '' // length = 5
    this.phone = '' // length = 16
    this.companyId = 0 // length = 20
    this.profilePicture = '' // length = 256
    this.permanentAddressDoorNumber = '' // length = 32
    this.permanentAddressStreet = '' // length = 128
    this.permanentAddressState = '' // length = 32
    this.permanentAddressCity = '' // length = 32
    this.permanentAddressPin = '' // length = 10
    this.permanentCountryName = '' // length = 128
    this.currentAddressDoorNumber = '' // length = 32
    this.currentAddressStreet = '' // length = 128
    this.currentAddressState = '' // length = 32
    this.currentAddressCity = '' // length = 32
    this.currentAddressPin = '' // length = 10
    this.currentCountryName = '' // length = 128
  }
}
