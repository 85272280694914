export class Certification {
  constructor() {
    this.certificationId = "";
    this.trainerId = null;
    this.certificationName = "";
    this.authorityName = "";
    this.awardedDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.validTill = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.nonExpiring = false;
  }
}
