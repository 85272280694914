
export default {
  primary: {
    base: "#1a237e",
    darken1: "#283593",
    lighten1: "#9fa8da",
  },
  secondary:"#000000",
  header: {
    color: "#ffffff",
    classes: "white--text",
    dark: true
  },
  footer: {
    color: "#000000",
    classes: "white--text",
    dark: true,
  },
  primaryBgText: "#ffffff",
  secondaryBgText: "#ffffff",
  dark: false,
  semidark: false,
  rtl: false,
};
// primary 
// base: "#1a237e"
// darken1: "#283593"
// lighten1: "#9fa8da"
// scondary 
// header

// classes: "white--text"
// color: "#000000"
// dark: true
// foooter


// classes: "white--text"
// color: "#000000"
// dark: true
