export class Patents {
  constructor() {
    this.trainerId = null;
    this.patentName = "";
    this.patentNumber = "";
    this.yearGranted = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
  }
}
