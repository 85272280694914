export class TrainerTargetAudience {
  constructor() {
    this.trainerId = null;
    this.targetAudienceId = null;
    this.targetAudienceType = "";
    this.trainerVerifiedTargetAudeince = false;
    this.dueDeligenceVerifiedTargetAudeince = false;
    this.targetAudienceName='';
  }
}
