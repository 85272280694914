import { Languages } from "./Languages";

export class UpdateTrainerProfile {
  constructor() {
    this.trainerId = 0;
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.primaryEmailId = null;
    this.secondaryEmail = null;
    this.primaryPhoneNumber = null;
    this.secondaryPhoneNumber = null;
    this.profilePic = "";
    this.otOwner = "";
    this.createdBy = "";
    this.creationDate = null;
    this.sourceType = "";
    this.summary = "";
    this.professionalOverview = "";
    this.trainerPersona = "";
    this.currentAddressDoorNumber = "";
    this.currentAddressStreet = "";
    this.currentAddressState = "";
    this.currentAddressCity = "";
    this.currentAddressPin = "";
    this.currentAddressCountry = "";
    this.linkedinProfileUrl = "";
    this.personalLinks = [];
    this.professionalLinks = null;
    this.trainerBand = "";
    this.trainerOtHistory = false;
    this.trainerOraginzation = "";
    this.baseLocation = "";
    this.trainerCode = "";
    this.trainerProcueredFrom = "";
    this.trainerOraginzation = "";
    this.listOfOqs = [];
    this.deliveredTrainingsThroughOt = [];
    this.dateAvailable = "";
    this.additionalConstraints = "";
    this.suggestedBy = "";
    this.linkToAsana = "";
    this.numberOfOq = 0;
    this.rawCoursesTrainer = "";
    this.validationDataForSkills = "";
    this.socialLinks = {
      twitter: "",
      instagram: "",
      facebook: ""
    };
    this.personalLinks = {
      personalWeb1: "",
      personalWeb2: ""
    };
    this.professionalLinks = {
      udemy: "",
      pluralsight: ""
    };
    this.languages = {
      traningDelivery: new Languages(),
      regionalLanguage: new Languages()
    };
  }
}
