/* globals Router, Store */
import axios from 'axios'

const BASE_URL_SSO = process.env.VUE_APP_BASE_URL_SSO

const instance = axios.create({
  baseURL: "",
  timeout: false,
  params: {} // do not remove this, its added to add params later in the config
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  
  console.log("Config in Request ---", config)
  const token = Store.state.userAuthenticaiton?Store.state.userAuthenticaiton.token: null;
  if (token) {
    // 
    console.log('token found', token);
    config.headers.common['Authorization'] = 'Bearer ' + token
    config.headers.common['Access-Control-Allow-Origin'] = '*'
   config.headers.common['user-auth-token'] = token
   config.headers.common['user-auth-token'] = token 
  } else {
    // Use application/x-www-form-urlencoded for login
    config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
   }
  return config
}, function (error) {
  // Do something with request error
  console.log("request ERROR >>> ", error);
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => response, (error) => {
  console.log(" RESPONSE ERROR", error.response);
  
  if (error.response === undefined || error.response.status === undefined) {
    console.log('server not working ...')
    // Store.dispatch('project/manageAlertBox',
    //   {
    //     status: true,
    //     text: 'Server Got Fatal Error, Will Fix soon! Please login again.',
    //     'headerText':"Failed",
    //   })
  } else if (error.response.status === 401 && Router.currentRoute.name !== 'Login') {
  
    console.log('unauthorized, logging out ...')
    Store.dispatch('project/manageAlertBox',
    {
      status: true,
      text: error.response.data.statusMessage,
      'headerText': "Failed to login",
      'afterOk': () => {}
    })
  } else {
    Store.dispatch('project/manageAlertBox',
      {
        status: true,
        text: error.response.statusMessage,
        'headerText':"Failed to login",
      })
  }
  return Promise.reject(error)
})

export default {

  getData (action) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.get(url)
  },
  postData (action, data) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.post(url, data)
  },
  putData (action, data) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.put(url, data)
  },
  deleteData (action) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.delete(url)
  },
  uploadData (action, data) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  downloadData (action) {
    let url = `${BASE_URL_SSO}`
    url += action
    return instance.get(url, {
      responseType: 'blob'
    })
  },
  login(action, data) {
    let url = `${BASE_URL_SSO}`
    url += action
    
    return instance.get(url, {
      headers: { 'Authorization': data }
    })
  }
}
