export class SkillServicesDto{
    constructor() {
        this.primarySkills=[];
		// tools platfrom and methodology 
		this.toolsAndPlatFrom=[];
		// target auddince for primary skills
        this.primarySkillsTargetAudience = null;
		// target industry and target department
		this.targetIndustryAndDepartment = [];
		this.targetDepartment = [];
		// service delivery expreince
		this.trainerServiceDelivery=null;
		// seconddry skills
		this.secondarySkills=[];
		// target audince for seconddry skills
		this.secondarySkillsTargetAudience=null;
		// aspirations skills 
		this.aspirationalSkills=[];
		// target audience for aspirational skills
		this.aspirationalSkillsTargetAudience = null;
		this.trainerId = null;
    }
}