<template>
  <v-app class="white">
    <v-container fluid>
      
      <router-view name="sidebar" />
      <router-view name="header" :handleSettingsDrawer="handleSettingsDrawer" />
      
      <v-main class="vuse-content">
        <AlertDailog :dialog="alertBoxStatus" :dialogText="alertBoxText" @onOk="alertBoxOk" 
        :sucessOrFailure="headerText" />
          <Loader :visible="loading"/>
        <v-fade-transition>
          <router-view />
        </v-fade-transition>
      </v-main>
      <router-view name="footer" />
      <app-settings-drawer
        :handleDrawer="handleSettingsDrawer"
        :drawer="settingsDrawer"
      />
    </v-container>
  </v-app>
</template>

<script>
import Loader from '../src/components/loader.vue'

import { mapState } from "vuex";
import AppSettingsDrawer from "@/layouts/App/AppSettingsDrawer";
import AlertDailog from "@/components/AlertDailog.vue";
export default {
  data: () => {
    return {
      settingsDrawer: false
    };
  },
 
  computed: {
    ...mapState("project", {
      alertBoxText: "alertBoxText",
      alertBoxStatus: "alertBoxStatus",
      afterOk: "afterOk",
      headerText:'headerText'
    }),
     ...mapState("trainerProfile", {
      loading: "loading",
    })
  },
  components: {
    AppSettingsDrawer,
    AlertDailog,
    Loader
  },
  methods: {
    handleSettingsDrawer() {
      this.settingsDrawer = !this.settingsDrawer;
    },
    alertBoxOk() {
      console.log("Inside alert box of app.vue");
      console.log('after ok' , this.afterOk);
      
      this.afterOk();
      this.$store.dispatch("project/manageAlertBox", {
        status: false,
        text: "",
        headerText:'',
        afterOk: () => {}
      });
    }
  }
};
</script>
<style>
.loader {
  margin: 50px;
}
</style>