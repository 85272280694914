// contains all the constas like folder name and all
export  default  {
    FILE_TYPE_LEGAL: "LEGAL",
    LEGAL_FOLDER_NAME_IN_S3: "legal_documents",

    FILE_TYPE_PAN: "PAN_CARD",
    PAN_FOLDER_NAME_IN_S3: "pan_card_documents",
    
    FILE_TYPE_GST: "GST",
    GST_FOLDER_NAME_IN_S3: "gst_documents",
    
    FILE_TYPE_BANK: "BANK_DETAILS",
    BANK_FOLDER_NAME_IN_S3: "bank_documents",
    
    FILE_TYPE_COMPANY_PAN: "COMPANY_PAN",
    COMPANY_PAN_FOLDER_NAME_IN_S3: "company_pan_documents",
    
    FILE_TYPE_COMPANY_MSME: "COMPANY_MSME",
    COMPANY_MSME_FOLDER_NAME_IN_S3:"company_msme_documents",
    
    FILE_TYPE_ORIGINAL_FILES_PROFILE: 'ORIGINAL_PROFILE',
    ORIGINAL_FILES_PROFILE_FOLDER_NAME_IN_S3: 'original_files_profile',
    

    FILE_TYPE_ORIGINAL_FILES_TEMPLATE: 'ORIGINAL_TEMPLATE',
    ORIGINAL_FILES_TEMPLATE_FOLDER_NAME_IN_S3: 'original_files_template',

    FILE_TYPE_COURSE_UPLOAD: "COURSE_UPLOAD",
    COURSE_UPLOAD_FOLDER_NAME_IN_S3: 'course_outline',
    FILE_TYPE_TESTIMONIAL_UPLOAD: 'TESTIMONIAL_UPLOAD',
    TESTIMONIAL_UPLOAD_FOLDER_NAME_IN_S3: "testimonial_upload",
    FILE_TYPE_REFERENCES_UPLOAD: "REFERENCES_UPLOAD",
    REFERENCES_UPLOAD_FOLDER_NAME_IN_S3:"references_upload"
	// public static final String DIR_BANK="BANK_DETAILS";
	// public static final String DIR_COMPANY_PAN="COMPANY_PAN";
    // public static final String DIR_COMPANY_MSME="COMPANY_MSME";
    
    // public static final String DIR_LEGAL="legal_documents";
	// public static final String DIR_PAN="pan_card_documents";
	// public static final String DIR_GST="gst_documents";
	// public static final String DIR_BANK="bank_documents";
	// public static final String DIR_COMPANY_PAN="company_pan_documents";
	// public static final String DIR_COMPANY_MSME="company_msme_documents";
}