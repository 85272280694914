export class TrainerFees {
  constructor(feesType) {
    this.trainerId = null;
    this.trainerMinimumFess = null;
    this.trainerMaximumFess = null;
    this.trainerStandardFess = null;
    this.trainerNegotiatedFess = null;
    this.trainerFeesType =feesType;
    // TWO_HOUR_FEES // Half_Day // Full_Day;
  }
}
