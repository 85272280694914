export class Legal {
  constructor() {
    this.legalId = null;
    this.trainerId = null;
    this.dateOfAgrrement = null
    this.legalStatus = "";
    this.legalDocumentUrl = "";
    this.listOfLegalExcetpions=[];
  }
	}
