// /* globals Store, Router */
/*eslint-disable */
const state = {
  loading: false, // network call button loading state
  alertBoxStatus: false, // alert box visibility
  alertBoxText: '',
  headerText:'',
 // header of modal
  afterOk: () => {}
}

const getters = {}

const actions = {
  // ----------------- Authentication and Authorization
  setLoading ({ commit }, status) {
    commit('setLoading', status)
  },
  // ----------------- Alert Boxes and Snackbar
  manageAlertBox ({ commit }, { status, text, afterOk ,headerText}) {
    commit('manageAlertBox', { status, text, afterOk ,headerText})
  },

}

const mutations = {
  manageAlertBox (state, { status, text, afterOk,headerText}) {
    state.alertBoxText = text
    state.alertBoxStatus = status
    state.afterOk = afterOk,
    state.headerText=headerText
  },
  setLoading (state, loading) {
    state.loading = loading
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
