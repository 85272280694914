export class Testimonial {
  constructor() {
    this.trainerId = null;
    this.competenceSkill = "";
    this.testimonialYear = null;
    this.customerPointOFContact = "";
    this.customerName = "";
    this.desigination = "";
    this.roleOfPoc = "";
    this.testimonialText = "";
    this.sourceOfTestimonial = "";
    this.testimonialAttachementUrl = "";
  }
}
//(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)