export class TrainerBankDetials {
  constructor() {
    this.trainerId = null;
    this.accountNumber = "";
    this.accountName = "";
    this.bankName = "";
    this.ifscCode = "";
    this.cancelledChequeUrl = "";
  }
}
