export class WorkExperince {
  constructor() {
    this.trainerId = null;
    this.companyName = "";
    this.designation = "";
    this.startDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.endDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10);
    this.rolesAndResponsibilites = "";
    this.department = "";
    this.natureOfEngagement = "";
    this.industry = "";
    this.keySkills = [];
    this.keyTools = [];
    this.currentlyWorking=false 
  }
}
