/* globals Store, Router */
/*eslint-disable */
import ssoApi from '@/utils/api/sso-api'
import { User } from '@/models'
const state = {
  loading: false, // network call button loading state
  user: new User(), // user details
  access: 'logout', // user Authorization
  token: '', // auth token
  s3Directory: 'https://s3.ap-south-1.amazonaws.com/prod-theseus-packages/project/admin/',
  afterOk: () => {}
}

const getters = {}

const actions = {
  // ----------------- Authentication and Authorization
  login ({ commit}, {email, password, cb}) {
    // Base64 Encoding -> btoa
    const data = 'Basic ' + btoa(email + ':' + password)
    console.log('data loging ', data);
    
    commit('setLoading', true)
    ssoApi.login('boot/login/admin', data)
      .then((res) => {
      
      if (res.data.status === 'SUCCESS' && res.data.data[0].authorities.indexOf('ROLE_ADMIN') !== -1) {
        commit('setToken', res.headers['user-auth-token'])
        let user = res.data.data[0]
        commit('setUser', user)
        commit('setAccess', user.status)
        cb()
        Router.push('/home' || '/')
        // Store.dispatch('project/manageAlertBox',
        //   {
        //     'status': true,
        //     'text': 'Sucessfully Autheticated',
        //     'headerText':"Sucessful",
        //     'afterOk': () => {
        //     Router.push('/home' || '/')
        //     }
        //   }
        //   )
      } else {
        console.log('res failed' , res.data.statusMessage)
        console.log('login failed');
        Store.dispatch('project/manageAlertBox',
        {
          'status': true,
          'text': 'Sucessfully Autheticated',
          'headerText':"Sucessful",
          'afterOk': () => {
            // this.$router.push('/home' || '/')
          }
        }
        )
      }
    })
      .catch(err => {
        commit('setLoading', false)
      console.log(err)
      let statusMessage = err.response.data.statusMessage
      if (statusMessage === 'unverified account') {
        Router.push('/pages/authentication/login')
      } else {
        Router.push('/pages/authentication/login')
      }
    })
    .finally(() => {
      commit('setLoading', false)
    })
  },
  logout({ commit, dispatch }) {
    console.log('logout callled ');
    commit('setLoading', true)

    ssoApi.putData('user/logout')
    .then(res => {
      if (res.data.status === 'SUCCESS') {
        commit('setLoading',false)
        commit('setToken', null)
        commit('setAccess', null)
        console.log("local storage" ,localStorage.length);
        localStorage.clear();
        console.log('Logout Success')
    
        
      } else {
        console.log(res.data)
        console.log('Logout failed from server')
      }
    })
    .catch(err => {
      console.log('Logout failed')
      console.log(err)
    })
    .finally(() => {
      //commit('setLoading', false)
       //dispatch('closeNotice', 0)
      // Store.dispatch('adminTrainer/logout')
      // Store.dispatch('trainerCalendar/logout')
      // Store.dispatch('trainerProfile/logout')
      // Store.dispatch('trainerUser/logout')
      // commit('logout')
      Router.push('/pages/authentication/login')
    })
  },
  checkLogin({state}) {
    if (!state.token) {
      Router.push('/pages/authentication/login')
    }
  },
  setLoading ({ commit }, status) {
    commit('setLoading', status)
  },
}

const mutations = {  
  setToken(state, token) {
    if (token) {
      state.token = token
    } else {
      state.token = ''
    }
    
  },
  setAccess (state, status) {
    if (status === 1) state.access = 'full'
    else state.access = 'logout'
  },
  setUser(state, user) {
    console.log("inside set user mutations user  ---", user)
    state.user = user
  },
  setLoading (state, loading) {
    state.loading = loading
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
