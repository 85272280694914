// Authentication Pages
function ifEditMode(to, from, next) {
  console.log('to' , to ,'from ' , from ,'next' , next);
}
export default [
  {
    params: {
      path: "/home",
      name: "Home",
    },
    defaultComponentPath: "Pages/searchPage/search",
    navs: true,

  },
  {
    params: {
      path: "/bluk-upload",
      name: "Bulk Upload",
    },
    defaultComponentPath: "Pages/BulkUpload/bulkUpload",
    navs: false,

  },
  {
    params: {
      path: "/manage-trainers",
      name: "Manage Trainers",
    },
    //defaultComponentPath: "Pages/searchPage/search",
     defaultComponentPath: "Pages/Home",
    navs: true,
  },
  {
    params: {
      path: "/linkedin-scraper",
      name: "Linkedin sccraper",
    },
    defaultComponentPath: "Pages/AddTrainerUsingLinkedin/addTrainerUsingLinkedin",
    
    navs: true,
  },
  {
    params: {
      path: "/manage-trainers",
      name: "pages/Home",
    },
    defaultComponentPath: "Pages/Home",
    navs: true,
  },
  {
    params: {
      path: "/skilldb",
      name: "Skilldb",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/ManageSkills/manageSkills",
    navs: true,
},
  {
    params: {
      path: "/pages/authentication/forgot-password",
      name: "pages/authentication/ForgotPasswordPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/ForgotPassword/ForgotPassword",
  },
  {
    params: {
      path: "/pages/createTrainer",
      name: "pages/createTrainer",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/CreateTrainer/CreateTrainer",
    navs: true,
  },
  {
    params: {
      path: "/pages/adminConsole",
      name: "pages/adminConsole",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/AdminConsole/AdminConsole",
    beforeEnter:ifEditMode,
    navs: true,
  },
   {
    params: {
      path: "/pages/workx",
      name: "pages/profile/WorkExp",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Profile/WorkExp",
    navs: true,
  },
  {
    params: {
      path: "/pages/skills-service",
      name: "pages/profile/SkillServices",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/ServicesSkills/servicesSkill",
    navs: true,
  },
  {
    params: {
      path: "/pages/topcourse",
      name: "pages/profile/Topcourse",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Profile/TopCourses",
    navs: true,
  },
  {
    params: {
      path: "/pages/searchPage",
      name: "pages/searchPage/search",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/searchPage/search",
    navs: true,
  },
  {
    params: {
      path: "/pages/originalFiles",
      name: "pages/originalFiles",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/OriginalFiles/OriginalFiles",
    navs: true,
  },
  {
    params: {
      path: "/pages/paymentDetails",
      name: "pages/paymentDetials",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/PaymentDetails/PaymentDetails",
    navs: true,
  },
  {
    params: {
      path: "/pages/legal",
      name: "pages/legal",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Legal/legal",
    navs: true,
  },
  {
    params: {
      path: "/pages/linkedInRaw",
      name: "pages/legal",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/LinkedInRawData/linkedInRaw",
    navs: true,
  },
  {
    params: {
      path: "/pages/addTrainerUsingLinkedIn",
      name: "pages/addTrainerUsingLinkedIn",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/AddTrainerUsingLinkedIn/addTrainerUsingLinkedIn",
    navs: true,
  },
  {
    params: {
      path: "/pages/trainerAccountCreation",
      name: "pages/trainerAccountCreation",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/TrainerAccountCreation/TrainerAccountCreation",
    navs: true,
  },
  {
    params: {
      path: "/pages/dashboard",
      name: "pages/dashboard",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/DashBoard/Dashboard",
    navs: true,
  },
  {
    params: {
      path: "/pages/authentication/signup",
      name: "pages/authentication/SignupPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/Signup/Signup",
  },
  {
    params: {
      path: "/pages/TrainerListingPage/trainers",
      name: "pages/trainers-listing/trainers",
      meta:{layout:"auth"},
    },
    defaultComponentPath:"Pages/TrainerListingPage/TrainersByMe",
  },
  {
    params: {
      path: "/pages/Certifications",
      name: "pages/profile/Certifications",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Certifications/certifications",
    navs: true,
  },
  {
    params: {
      path: "/pages/trainerDetialsView",
      name: "pages/TrainerDetialsView",
      meta: { layout: "auth" },
    },
    navs:true,
    defaultComponentPath: "Pages/TrainerDetialsView/TrainerDetials",
  },
  {
    params: {
      path: "/pages/testimonial",
      name: "pages/profile/Testimonials",
      meta: { layout: "auth" },
    },
    navs:true,
    defaultComponentPath: "Pages/Testimonials/Testimonial",
  },
  {
    params: {
      path: "/pages/refrences",
      name: "pages/profile/Refrences",
      meta: { layout: "auth" },
    },
    navs:true,
    defaultComponentPath: "Pages/Refrences/Refrences",
  },
  {
    params: {
      path: "/pages/authentication/lock-screen",
      name: "pages/authentication/LockScreenPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/LockScreen/LockScreen",
  },
  
  {
    params: {
      path: "*",
      name: "PageNotFound",
      meta: { layout: "full" },
    },
    defaultComponentPath: "Pages/Errors/Error404",
  },
  {
    params: {
      path: "/pages/authentication/login",
      name: "pages/authentication/LoginPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/Login/Login",
  },
];
