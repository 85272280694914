export class Publications {
  constructor() {
    this.trainerId = null;
    this.publicationType = "";
    //	Type
    this.publicationTitle = "";
    this.publishedName = ""; // publisher name
    this.publicationDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
  }
}
