export default class TrainerJourney {
  constructor() {
    this.trainerId = 0;
    this.dataVerfication = 1; // collect raw details
    this.basicDetials = 1; // basic details
    this.dueDeligence = 1;
    this.priceNegotiation = 1;
    this.legalSigning = 1;
    this.processAlignment = 1;
    // data richeness
    this.metaTag = 1;
    this.dataRichnes = 1;
  }
}
