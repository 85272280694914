import { TrainerProfile } from "./TrainerProfile";
export class LinkedinScrapperDto {
  constructor() {
    this.trainerProfile = new TrainerProfile();
    this.languages = [];
    this.workExperince = [];
    this.awards = [];
    this.patents = [];
    this.publication = [];
    this.education = [];
    this.certifications = [];
    this.scrappedDataId = null;
  }
}
